import { Box, Button, Flex, Icon, IconButton, Input } from "@chakra-ui/react";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IFilterableFieldOption } from "../types";
import LogLevelPicker from "./LogLevelPicker";
import ServiceNamePicker from "./ServiceNamePicker";
import SingleValueConditionEnvironmentPicker from "./SingleValueConditionEnvironmentPicker";
import SingleValueConditionSchemaPicker from "./SingleValueConditionSchemaPicker";
import SingleValueConditionSourcePicker from "./SingleValueConditionSourcePicker";

const ConditionTypeSwitch = ({
  type,
  field,
  value,
  onChange
}: {
  type: string;
  value: string | string[] | undefined | null;
  field: IFilterableFieldOption;
  onChange: (value: string | string[] | undefined | null) => void;
}) => {
  const isArrayValuePicker = type === "IN";

  const getFieldInputTypeComponent = function (val: string, index: number) {
    switch (field.inputType) {
      case "SOURCE_PICKER":
        return (
          <SingleValueConditionSourcePicker
            value={val}
            onSelect={(e) => {
              if (!isArrayValuePicker) {
                return onChange(e);
              }

              const updatedValue = [...(value as string[])];
              updatedValue[index] = e!;
              return onChange(updatedValue);
            }}
          />
        );
      case "ENVIRONMENT_PICKER":
        return (
          <SingleValueConditionEnvironmentPicker
            value={val}
            onSelect={(e) => {
              if (!isArrayValuePicker) {
                return onChange(e);
              }

              const updatedValue = [...(value as string[])];
              updatedValue[index] = e!;
              return onChange(updatedValue);
            }}
          />
        );
      case "MAPPING_SCHEMA_PICKER_BY_ID":
        return (
          <SingleValueConditionSchemaPicker
            value={val}
            onSelect={(e) => {
              if (!isArrayValuePicker) {
                return onChange(e);
              }

              const updatedValue = [...(value as string[])];
              updatedValue[index] = e!;
              return onChange(updatedValue);
            }}
          />
        );
      case "LOG_LEVEL_PICKER":
        return (
          <LogLevelPicker
            value={val}
            onChange={(e) => {
              if (!isArrayValuePicker) {
                return onChange(e);
              }

              const updatedValue = [...(value as string[])];
              updatedValue[index] = e;
              return onChange(updatedValue);
            }}
          />
        );
      case "TEXT":
        return (
          <Input
            data-1p-ignore
            autoFocus
            value={val ?? ""}
            onChange={(e) => {
              if (!isArrayValuePicker) {
                return onChange(e.target.value);
              }

              const updatedValue = [...(value as string[])];
              updatedValue[index] = e.target.value;
              return onChange(updatedValue);
            }}
            placeholder="Your value"
          />
        );
      case "SERVICE_PICKER":
        return (
          <ServiceNamePicker
            value={val}
            onChange={(e) => {
              if (!isArrayValuePicker) {
                return onChange(e);
              }

              const updatedValue = [...(value as string[])];
              updatedValue[index] = e;
              return onChange(updatedValue);
            }}
          />
        );
    }
  };

  const addNewMultiSelectValue = () => {
    const updatedValue = [...(value as string[])];
    updatedValue.push("");
    onChange(updatedValue);
  };

  const removeMultiSelectValue = (index: number) => {
    const updatedValue = [...(value as string[])];
    updatedValue.splice(index, 1);
    onChange(updatedValue);
  };

  return (
    <div>
      {!isArrayValuePicker ? (
        <div>{getFieldInputTypeComponent(value as string, 0)}</div>
      ) : (
        <>
          <ul>
            {(Array.isArray(value) ? value : []).map((val, index) => (
              <Flex key={index} gap="2" py="2" alignItems="center">
                <Box flex="1">{getFieldInputTypeComponent(val, index)}</Box>
                <IconButton
                  aria-label="Remove"
                  colorScheme="red"
                  color="red"
                  variant="ghost"
                  onClick={() => removeMultiSelectValue(index)}
                  icon={<Icon as={FontAwesomeIcon} icon={faClose}></Icon>}
                />
              </Flex>
            ))}
          </ul>
          <div>
            <Button
              variant={"ghost"}
              colorScheme="gray"
              onClick={addNewMultiSelectValue}
            >
              Click to add another value
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ConditionTypeSwitch;
