import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { SOURCE_GROUPS } from "../../../constants/queryKey";
import { SourceGroupService } from "../source-groups.service";

const getSourceGroupsFn = () => {
  const sourceGroupService = container.resolve(SourceGroupService);
  return sourceGroupService.getSourceGroups();
};

export const useSourceGroups = () => {
  return useQuery({
    queryKey: [SOURCE_GROUPS],
    queryFn: getSourceGroupsFn
  });
};
