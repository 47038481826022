import { SchemaFormat, SchemaType } from "../types";
import { TestInput, TestInputSourceEntity } from "./types";

const defaultTestSourceEntity = {
  type: "",
  originId: "",
  originParentId: null,
  url: "#",
  redirects: [],
  properties: {}
};

export const validateInputType = (
  data: TestInput | TestInputSourceEntity,
  schemaFormat: SchemaFormat,
  schemaType: SchemaType
): data is TestInputSourceEntity => {
  return !(schemaFormat === "javascript" && schemaType === "partial");
};

export const getDefaultTestData = (
  schemaFormat: SchemaFormat,
  schemaType: SchemaType
) => {
  const picker: {
    [format in SchemaFormat]: { [type in SchemaType]: TestInput };
  } = {
    json: {
      normal: { ...defaultTestSourceEntity },
      partial: { ...defaultTestSourceEntity },
      collection: { ...defaultTestSourceEntity }
    },
    javascript: {
      normal: { ...defaultTestSourceEntity },
      partial: {},
      collection: { ...defaultTestSourceEntity }
    }
  };

  return picker[schemaFormat][schemaType];
};

export const validateTestSourceEntityInputData = (
  data: TestInputSourceEntity
): [boolean, string] => {
  if (!data.type) {
    return [false, "JSON type must not be empty"];
  }
  return [true, ""];
};
