import { Box, useToast } from "@chakra-ui/react";
import Lottie from "lottie-react";
import { createElement, useEffect, useState } from "react";
import LoadingAnimation from "../../../components/LoadingAnimation";
import { defaultToast } from "../../../constants/toast";
import useMixPanel from "../../../mixpanel/useMixPanel";
import ISelectOption from "../../../types/selectInput";
import { useEnvironments } from "../../environments/api/getEnvironments";
import { IEnvironmentResponse } from "../../environments/types";
import { useCreateRelease } from "../api/createRelease";
import { useDeploymentsFromEnvironment } from "../api/getDeploymentsFromEnvironment";
import lottieCompleted from "../lottie/bulk-deploy-schemas-completed.json";
import lottieLoading from "../lottie/bulk-deploy-schemas.json";
import { ISchemaRelease } from "../types";
import {
  BulkDeploy,
  copyVersionsFromEnv,
  sortBulkDeployList
} from "../types/BulkDeploy";
import { BulkDeployEnvironmentPicker } from "./BulkDeployEnvironmentPicker";
import { BulkDeployPanelFooter } from "./BulkDeployPanelFooter";
import BulkDeployTable from "./BulkDeployTable";

export const BulkDeployPanelContent = ({
  onCancel,
  preSelectedEnvironment
}: {
  onCancel: () => void;
  preSelectedEnvironment?: IEnvironmentResponse;
}) => {
  const { data: environments, isLoading } = useEnvironments();

  const [preSelectedEnvironmentOption, setPreSelectedEnvironmentOption] =
    useState<ISelectOption | undefined>(
      preSelectedEnvironment
        ? {
            label: preSelectedEnvironment.name,
            value: preSelectedEnvironment?.id.environmentGuid
          }
        : undefined
    );

  const [selectedEnvironment, setSelectedEnvironment] = useState<
    ISelectOption | undefined
  >();

  useEffect(() => {
    if (!preSelectedEnvironment) {
      return;
    }
    if (
      preSelectedEnvironment.id.environmentGuid !==
      preSelectedEnvironmentOption?.value
    ) {
      setPreSelectedEnvironmentOption(
        preSelectedEnvironment
          ? {
              label: preSelectedEnvironment.name,
              value: preSelectedEnvironment?.id.environmentGuid
            }
          : undefined
      );
    }
  }, [preSelectedEnvironment]);

  useEffect(() => {
    if (!preSelectedEnvironmentOption) {
      return;
    }
    setSelectedEnvironment(preSelectedEnvironmentOption);
  }, [preSelectedEnvironmentOption]);

  const [copyEnvironment, setCopyEnvironment] =
    useState<ISelectOption | null>();

  const [schemas, setSchemas] = useState<BulkDeploy[]>([]);
  const [deployConfig, setDeployConfig] = useState<BulkDeploy[]>([]);

  const mixpanel = useMixPanel();

  const { data: copyEnvironmentSchemas } = useDeploymentsFromEnvironment({
    environmentGuid: copyEnvironment?.value as string
  });

  const { data, isLoading: isLoadingDeployments } =
    useDeploymentsFromEnvironment({
      environmentGuid: selectedEnvironment?.value as string
    });

  const release = useCreateRelease({
    environmentGuid: selectedEnvironment?.value as string
  });

  const toast = useToast();

  const deploy = () => {
    if (!selectedEnvironment) {
      throw new Error("environmentId must be selected");
    }
    const environmentId = `gid://Environment/${
      selectedEnvironment?.value as string
    }`;
    const releases = deployConfig.map(
      (d): ISchemaRelease => ({
        schemaId: d.id,
        version: !d.deployVersion?.value
          ? null
          : parseInt(d.deployVersion?.value.toString())
      })
    );
    toast.promise(
      release.mutateAsync({
        environmentId,
        schemas: releases
      }),
      {
        success: {
          title: `Successfully deployed ${releases.length} schemas`,
          description: createElement(Lottie, {
            animationData: lottieCompleted,
            style: { height: "120px", width: "100%" }
          }),
          ...defaultToast
        },
        loading: {
          title: `Deploying ${releases.length} schemas`,
          description: createElement(Lottie, {
            animationData: lottieLoading,
            color: "transparent"
          }),
          ...defaultToast
        },
        error: { title: `Deployment failed`, ...defaultToast }
      }
    );
    onCancel();

    mixpanel.track("bulkDeploy", { releases, environmentId });
  };

  const updateTableData = () => {
    setSchemas([...schemas]);
  };

  useEffect(() => {
    if (data && copyEnvironmentSchemas && copyEnvironment) {
      setSchemas(copyVersionsFromEnv(data, copyEnvironmentSchemas));
    } else if (data) {
      setSchemas([...sortBulkDeployList(data)]);
    }
  }, [data, copyEnvironmentSchemas, copyEnvironment]);

  useEffect(() => {
    setDeployConfig(
      schemas
        .filter((schema) => !schema.exclude)
        .filter((schema) => schema.state !== "Unchanged")
    );
  }, [schemas]);

  const environmentOptions = (): ISelectOption[] =>
    environments
      ? environments.map(
          (item): ISelectOption => ({
            label: item.name,
            value: item.id.environmentGuid
          })
        )
      : [];

  return (
    <>
      {!isLoading ? (
        <BulkDeployEnvironmentPicker
          label="Environment"
          placeholder="Select environment"
          value={selectedEnvironment}
          options={environmentOptions()}
          onChange={(value) => {
            setSelectedEnvironment(value);
            setCopyEnvironment(null);
          }}
        ></BulkDeployEnvironmentPicker>
      ) : (
        <LoadingAnimation></LoadingAnimation>
      )}
      {selectedEnvironment && (
        <Box mt="8px">
          <BulkDeployEnvironmentPicker
            label="Copy versions from another environment"
            placeholder="Select copy environment"
            value={copyEnvironment}
            onChange={(option) => {
              setCopyEnvironment(option);
              if (option?.value) {
                mixpanel.track("bulkDeployCopyFromEnvironment", {
                  toEnvironmentId: `gid://Environment/${
                    selectedEnvironment.value as string
                  }`,
                  fromEnvironmentId: `gid://Environment/${
                    option.value as string
                  }`
                });
              }
            }}
            options={environmentOptions().filter(
              (e) => e.value !== selectedEnvironment.value
            )}
          ></BulkDeployEnvironmentPicker>
          {!isLoadingDeployments ? (
            <>
              <BulkDeployTable schemas={schemas} onChange={updateTableData} />
              <BulkDeployPanelFooter
                deployConfig={deployConfig}
                environmentGuid={selectedEnvironment.value as string}
                cancel={() => {
                  mixpanel.track("bulkDeployCancelled");
                  onCancel();
                }}
                onSubmit={deploy}
              ></BulkDeployPanelFooter>
            </>
          ) : (
            <LoadingAnimation></LoadingAnimation>
          )}
        </Box>
      )}
    </>
  );
};
