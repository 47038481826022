import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { SCHEMAS } from "../../../constants/queryKey";
import { SchemaService } from "../schema.service";

export const getSchemas = async (query?: string) => {
  const schemaService = container.resolve(SchemaService);

  return !query
    ? schemaService.getSchemas()
    : schemaService.searchSchemas(query);
};

export const useSchemas = (query?: string) => {
  return useQuery({
    queryKey: [SCHEMAS, query],
    queryFn: () => getSchemas(query)
  });
};
