import { useMutation, useQueryClient } from "@tanstack/react-query";
import { container } from "tsyringe";
import { useShallow } from "zustand/react/shallow";
import {
  DATA_SOURCES,
  DOMAINS,
  ENVIRONMENTS,
  ENVIRONMENT_CLIENTS,
  HAS_INGESTED_ANY_ENTITIES,
  SCHEMAS
} from "../../../constants/queryKey";

import { useAuthStore } from "../../auth/store";
import { DatasetsService } from "../../datasets/datasets.service";
import { useTenantStore } from "../store";
import { TenantService } from "../tenants.service";
import { ITenantCreate } from "../types";

const createTenantFn = async ({ tenant }: ITenantCreate) => {
  const tenantService = container.resolve(TenantService);
  const { tenantGuid } = await tenantService.createTenant(tenant);
  const [newTenant] = await tenantService.getByIds([tenantGuid]);
  return newTenant;
};

export const useCreateTenant = () => {
  const queryClient = useQueryClient();
  const addTenant = useTenantStore(useShallow((state) => state.addTenant));
  const { addTenant: addTenantToUser } = useAuthStore(
    useShallow(({ addTenant }) => ({ addTenant }))
  );

  return useMutation({
    mutationFn: createTenantFn,
    onSuccess: (tenant) => {
      queryClient.clear();
      addTenant(tenant);
      addTenantToUser(tenant);
    }
  });
};

const importDemoData = () => {
  const datasetsService = container.resolve(DatasetsService);
  return datasetsService.importJsDemoDataset();
};

export const useDemoDataImport = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: importDemoData,
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: [ENVIRONMENTS] }),
        queryClient.invalidateQueries({ queryKey: [DATA_SOURCES] }),
        queryClient.invalidateQueries({ queryKey: [ENVIRONMENT_CLIENTS] }),
        queryClient.invalidateQueries({ queryKey: [DOMAINS] }),
        queryClient.invalidateQueries({
          queryKey: [HAS_INGESTED_ANY_ENTITIES]
        }),
        queryClient.invalidateQueries({ queryKey: [SCHEMAS] })
      ])
  });
};
