import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import { faArrowUpArrowDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useShallow } from "zustand/react/shallow";
import TenantSwitchConfirmModal from "../../features/tenants/components/TenantSwitchConfirmModal";
import useTenantSwitcher from "../../features/tenants/hooks/useTenantSwitcher";
import { useTenantStore } from "../../features/tenants/store";
import { ITenant } from "../../features/tenants/types";
import useAdminFeatures from "../../helpers/useAdminFeatures";
import CopyableText from "../table/CopyableText";

const TenantPicker = () => {
  const adminFeaturesEnabled = useAdminFeatures();
  const { availableTenants, activeTenant } = useTenantStore(
    useShallow(({ availableTenants, activeTenant }) => ({
      availableTenants,
      activeTenant
    }))
  );

  const [switchToTenant, setSwitchToTenant] = useState<ITenant | undefined>(
    undefined
  );

  const tenantSwitcher = useTenantSwitcher();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleTenantSwitch = (tenant: ITenant) => {
    onClose();
    tenantSwitcher.switch(tenant);
  };

  const menuColorMode = useColorModeValue("black", "white");

  return availableTenants.length ? (
    <>
      <Menu key={"tenant-picker-menu"}>
        {({ isOpen }) => (
          <>
            <MenuButton
              key={"tenant-picker-menu-button"}
              isActive={isOpen}
              as={Button}
              color={menuColorMode}
              variant="ghost"
              mr="5"
              rightIcon={
                <Icon
                  as={FontAwesomeIcon}
                  icon={faArrowUpArrowDown}
                  color="brand.700"
                  transform={isOpen ? "" : "rotate(90deg)"}
                />
              }
            >
              <Flex flexDirection="column" alignItems="flex-start">
                <Text fontSize="x-small" fontWeight="normal" color="gray.400">
                  Tenant
                </Text>

                <Text fontSize={"small"}>{activeTenant.name}</Text>
              </Flex>
            </MenuButton>
            <MenuList
              key={"tenant-picker-menu-list"}
              maxH={"30vh"}
              overflowY={"auto"}
            >
              {[...availableTenants]
                .reverse()
                .sort((a, b) => (a.name < b.name ? -1 : 1))
                .sort((a) => (a.id.idValue === activeTenant.id ? -1 : 1))
                .map((tenant) => {
                  return (
                    <Box key={`${tenant.id.idValue}`}>
                      <MenuItem
                        onClick={() => {
                          if (tenant.id.idValue === activeTenant.id) {
                            return;
                          }
                          setSwitchToTenant(tenant);
                          onOpen();
                        }}
                        fontWeight={
                          tenant.id.idValue === activeTenant.id ? "bold" : ""
                        }
                        cursor={
                          tenant.id.idValue === activeTenant.id
                            ? "not-allowed"
                            : "pointer"
                        }
                      >
                        <Flex direction={"column"}>
                          <Flex justify={"space-between"} align={"center"}>
                            {tenant.name}
                            {!tenant.isUsingSourceGroups && (
                              <Badge
                                color={"gray.100"}
                                textColor={"gray.400"}
                                fontSize={"xx-small"}
                              >
                                deprecated
                              </Badge>
                            )}
                          </Flex>
                          {adminFeaturesEnabled && (
                            <CopyableText id={tenant.id.idValue}></CopyableText>
                          )}
                        </Flex>
                      </MenuItem>
                      <MenuDivider />
                    </Box>
                  );
                })}
            </MenuList>
          </>
        )}
      </Menu>
      {switchToTenant && (
        <TenantSwitchConfirmModal
          isOpen={isOpen}
          onClose={onClose}
          switchToTenant={switchToTenant}
          handleTenantSwitch={handleTenantSwitch}
        ></TenantSwitchConfirmModal>
      )}
    </>
  ) : (
    <></>
  );
};

export default TenantPicker;
