import { GroupBase, Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import EnterspeedIdentifiableSelect from "../../../components/EnterspeedIdentifiableSelect";
import {
  getSchemaAliasOptions,
  getSchemaOptionById
} from "../../../helpers/getSchemaAliasOptions";
import useReactEnterspeedIdentifiableSelectStyles from "../../../styles/react-enterspeed-identifiable-select-style";
import IEnterspeedIdentifiableSelectOption from "../../../types/identifiableSelectInput";
import { useSchemas } from "../../schemas/api/getSchemas";

const SingleValueConditionSchemaPicker = ({
  onSelect,
  value
}: {
  value: string | null;
  onSelect(value: string | null | undefined): void;
}) => {
  const { data: dataSchemas } = useSchemas();
  const schemaOptions = getSchemaAliasOptions(dataSchemas, "FULL");
  const reactSelectStyles = useReactEnterspeedIdentifiableSelectStyles();
  const [selectedSchema, setSelectedSchema] = useState<
    IEnterspeedIdentifiableSelectOption | undefined
  >();

  useEffect(() => {
    if (!dataSchemas) {
      return;
    }

    const dataSchemaOption = value
      ? getSchemaOptionById(dataSchemas, value, "FULL")
      : undefined;
    setSelectedSchema(dataSchemaOption);
  }, [dataSchemas, value]);

  const handleSchemaFilter = (value: IEnterspeedIdentifiableSelectOption) => {
    setSelectedSchema(value);
    onSelect(value?.value as string | null);
  };

  return (
    <Select<
      IEnterspeedIdentifiableSelectOption,
      true,
      GroupBase<IEnterspeedIdentifiableSelectOption>
    >
      size="sm"
      useBasicStyles
      chakraStyles={reactSelectStyles}
      colorScheme="brand"
      placeholder="Select schema"
      options={schemaOptions}
      components={new EnterspeedIdentifiableSelect()}
      value={selectedSchema}
      onChange={(value) => {
        return handleSchemaFilter(
          value as unknown as IEnterspeedIdentifiableSelectOption
        );
      }}
    />
  );
};

export default SingleValueConditionSchemaPicker;
