import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { container } from "tsyringe";
import { useShallow } from "zustand/react/shallow";
import LoadingAnimation from "../components/LoadingAnimation";
import useAuth from "../features/auth/api/authenticate";
import { useAuthStore } from "../features/auth/store";
import { SchemaVersionFormatModule } from "../features/schemas/formats/SchemaVersionFormat.module";
import { useTenantStore } from "../features/tenants/store";
import { HttpModule } from "../lib/http.module";

const ProtectedRoute = () => {
  const flags = useFlags();
  const auth = useAuth();

  const { isValid, user } = useAuthStore(
    useShallow((state) => ({ isValid: state.isValid, user: state.user }))
  );
  const { currentTenantProperties } = useTenantStore(
    useShallow(
      ({ availableTenants, activeTenant: currentTenantProperties }) => ({
        availableTenants,
        currentTenantProperties
      })
    )
  );

  useEffect(() => {
    container.resolve(HttpModule);
    container.resolve(SchemaVersionFormatModule);
    auth.authenticate().catch(console.error);
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    Sentry.setUser({
      id: user.id,
      roles: user.roles
    });

    if (!currentTenantProperties.id || !flags) {
      return;
    }

    Sentry.setContext("tenant", { ...currentTenantProperties, ...flags });
  }, [user, currentTenantProperties, flags]);

  return isValid ? <Outlet></Outlet> : <LoadingAnimation></LoadingAnimation>;
};

export default ProtectedRoute;
