import { Button, Icon } from "@chakra-ui/react";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLogsState } from "../contexts/logs";

const ToggleDisplayModeButton = () => {
  const { state, dispatch } = useLogsState();
  const showRawIds = state.displayType === "ID_VALUE_LINK";
  return (
    <Button
      leftIcon={
        <Icon
          as={FontAwesomeIcon}
          icon={showRawIds ? faEye : faEyeSlash}
        ></Icon>
      }
      variant="ghost"
      onClick={() =>
        showRawIds
          ? dispatch({
              type: "set_display_type",
              value: "DISPLAY_VALUE_LINK"
            })
          : dispatch({
              type: "set_display_type",
              value: "ID_VALUE_LINK"
            })
      }
    >
      Raw ids
    </Button>
  );
};
export default ToggleDisplayModeButton;
