import { Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { SchemaFormat, SchemaType } from "../../schemas/types";
import { ISchema } from "../types";

const SchemaRecordPreview = ({
  data: { name, viewHandle, type, format, version }
}: {
  data: ISchema;
}) => {
  const schemasType: { [key in SchemaType]: string } = {
    normal: "Full schema",
    partial: "Partial schema",
    collection: "Collection schema"
  };
  const schemaFormat: { [key in SchemaFormat]: string } = {
    javascript: "JS",
    json: "JSON"
  };
  return (
    <>
      <Heading fontSize={"2em"}> {name}</Heading>

      <Grid gridTemplateColumns={"35% 65%"} gridTemplateRows="auto">
        <GridItem>
          <Text fontWeight={500}>Schema type:</Text>
        </GridItem>
        <GridItem>
          <Text fontSize="sm">{schemasType[type]}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight={500}>Schema format:</Text>
        </GridItem>
        <GridItem>
          <Text fontSize="sm">{schemaFormat[format]}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight={500}>Alias:</Text>
        </GridItem>
        <GridItem>
          <Text fontSize="sm">{viewHandle}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight={500}>Version:</Text>
        </GridItem>
        <GridItem>
          <Text fontSize="sm">{version}</Text>
        </GridItem>
      </Grid>
    </>
  );
};

export default SchemaRecordPreview;
