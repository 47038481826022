import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const MANIFEST = "/asset-manifest.json";
const QUERY_KEY = "APP_VERSION";

const getLatestVersion = async () => {
  const { data } = await axios.get<string>(MANIFEST);
  return data;
};

const useLatestAppVersion = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: getLatestVersion,
    refetchInterval: 300_000, // every 5th minute
    refetchIntervalInBackground: true
  });
};

export default useLatestAppVersion;
