import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue,
  useMediaQuery
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyState from "../../../components/EmptyState";
import LoadingAnimation from "../../../components/LoadingAnimation";
import ReloadButton from "../../../components/ReloadButton";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import useAdminFeatures from "../../../helpers/useAdminFeatures";
import { EmptyStateType } from "../../../types/emptyState";
import { useEnvironments } from "../../environments/api/getEnvironments";
import { getSchemasFromEnvironment } from "../api/getSchemasFromEnvironment";
import {
  IDeployedSchemaVersions,
  IDeployedSchemaVersionsResponseCurrentSchema
} from "../types";

const VersionsOverview = () => {
  const [loading, setLoading] = useState(false);
  const [schemaVersions, setSchemaVersions] = useState<
    IDeployedSchemaVersions[]
  >([]);
  const [hoverElement, setHoverElement] = useState<string | null>(null);
  const [noEnvironmentsFound, setNoEnvironmentsFound] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  const [isSmallerThan900] = useMediaQuery("(max-width: 899px)");

  const navigate = useNavigate();

  const adminFeaturesEnabled = useAdminFeatures();

  const cellHeight = adminFeaturesEnabled ? "100px" : "65px";

  const { data: environments, isLoading: environmentsIsLoading } =
    useEnvironments();

  const numberOfEnvironments = environments?.length || 0;

  const goToSchema = (schemaId: string) => {
    navigate(`/schemas/${schemaId}`);
  };

  const fetchSchemasFromEnv = async (refetch?: boolean) => {
    setLoading(true);
    if (refetch) {
      setIsRefetching(true);
    }

    const schemaVersionsHolder: IDeployedSchemaVersions[] = [];

    if (!environments) {
      setNoEnvironmentsFound(true);
      setLoading(false);
      return;
    }

    for (const env of environments) {
      const schemasFromEnv = await getSchemasFromEnvironment({
        id: env.id.environmentGuid.replace("gid://Environment/", "")
      });

      schemasFromEnv.forEach(
        (schema: IDeployedSchemaVersionsResponseCurrentSchema) => {
          // If schema is already in the array, add the environment to the array
          const schemaIndex = schemaVersionsHolder.findIndex(
            (s) => s.id === schema.id.idValue
          );

          if (schemaIndex !== -1) {
            schemaVersionsHolder[schemaIndex].environments.push({
              environment: env.name,
              id: env.id.idValue,
              schemaVersionDeployed: schema.version
            });
            return;
          }

          schemaVersionsHolder.push({
            name: schema.name,
            alias: schema.alias,
            id: schema.id.idValue,
            guid: schema.id.mappingSchemaGuid,
            environments: [
              {
                environment: env.name,
                id: env.id.idValue,
                schemaVersionDeployed: schema.version
              }
            ]
          });
        }
      );
    }

    schemaVersionsHolder.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    setSchemaVersions(schemaVersionsHolder);
    setLoading(false);
    setIsRefetching(false);
  };

  useEffect(() => {
    if (!environmentsIsLoading) {
      fetchSchemasFromEnv().catch(console.error);
    }
  }, [environmentsIsLoading]);

  const HeaderActions = () => (
    <Flex justifyContent="flex-end" mb="4">
      <ReloadButton
        alignRight={false}
        loading={isRefetching}
        onClick={() => fetchSchemasFromEnv(true)}
      />
    </Flex>
  );

  const TableHeader = () => {
    const evenColors = useColorModeValue("gray.50", "gray.800");
    const bgColors = useColorModeValue("white", "gray.900");
    return (
      <SimpleGrid
        gridTemplateColumns={`500px repeat(${numberOfEnvironments}, 150px)`}
        fontSize="sm"
        position="sticky"
        top={0}
        zIndex={1}
      >
        <Box
          borderBottom="1px solid"
          borderColor="gray.200"
          position="sticky"
          top={0}
          left={0}
          backgroundColor={useColorModeValue("white", "gray.900")}
          boxShadow="5px 0 5px -2px rgba(0,0,0,.05)"
        >
          {
            // Intentionally left blank
          }
        </Box>
        {environments?.map((environment) => (
          <Box
            key={`${environment.id.idValue}-header`}
            borderBottom="1px solid"
            borderColor="gray.200"
            _even={{
              backgroundColor: evenColors
            }}
            backgroundColor={bgColors}
            fontWeight="bold"
            padding="3"
            height="100%"
            textAlign="center"
            wordBreak="break-word"
          >
            {environment.name}
          </Box>
        ))}
      </SimpleGrid>
    );
  };

  const SchemaNamesColumn = () => {
    const hoverColors = useColorModeValue("gray.100", "gray.700");
    const bgColors = useColorModeValue("white", "gray.900");
    return (
      <Box
        position="sticky"
        left={0}
        backgroundColor="white"
        boxShadow="5px 0 5px -2px rgba(0,0,0,.05)"
      >
        {schemaVersions.map((schema) => (
          <Box
            key={schema.id}
            height={cellHeight}
            p="3"
            borderBottom="1px solid"
            borderColor="gray.200"
            onMouseEnter={() => setHoverElement(schema.id)}
            onMouseLeave={() => setHoverElement(null)}
            onClick={() => goToSchema(schema.guid)}
            backgroundColor={
              hoverElement === schema.id ? hoverColors : bgColors
            }
            cursor={hoverElement === schema.id ? "pointer" : "initial"}
          >
            <Box
              maxWidth="490px"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="sm"
            >
              {schema.name}
            </Box>
            <Box
              fontStyle="italic"
              color="gray.500"
              maxWidth="490px"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="sm"
            >
              {schema.alias}
            </Box>
            <AdminTableRowId id={schema.id} />
          </Box>
        ))}
      </Box>
    );
  };

  const EnvironmentSchemaVersionsColumn = () => {
    const bgColors = useColorModeValue("gray.50", "gray.800");
    const hoverColors = useColorModeValue("gray.100", "gray.700");
    return (
      <>
        {environments?.map((environment) => (
          <Box
            key={`${environment.id.idValue}-versions`}
            _even={{
              backgroundColor: bgColors
            }}
            backgroundColor={"initial"}
          >
            {schemaVersions.map((schema) => {
              const schemaInEnvironment = schema.environments.find(
                (e) => e.id === environment.id.idValue
              );
              return (
                <Box
                  key={`${schema.id}-for-${environment.id.idValue}`}
                  height={cellHeight}
                  p="3"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  borderBottom="1px solid"
                  borderColor="gray.200"
                  fontSize="sm"
                  onMouseEnter={() => setHoverElement(schema.id)}
                  onMouseLeave={() => setHoverElement(null)}
                  onClick={() => goToSchema(schema.guid)}
                  backgroundColor={
                    hoverElement === schema.id ? hoverColors : "initial"
                  }
                  cursor={hoverElement === schema.id ? "pointer" : "initial"}
                >
                  {schemaInEnvironment ? (
                    <Tooltip
                      label={
                        <Box textAlign="center">
                          <Box mb="1">
                            <Text as="b">Environment</Text>
                          </Box>
                          <Box fontStyle="italic">{environment.name}</Box>
                        </Box>
                      }
                    >
                      <Box
                        width="32px"
                        height="32px"
                        color="white"
                        backgroundColor="gray.700"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="full"
                        key={`${schema.id}-for-${environment.id.idValue}`}
                      >
                        {schemaInEnvironment.schemaVersionDeployed}
                      </Box>
                    </Tooltip>
                  ) : (
                    "-"
                  )}
                </Box>
              );
            })}
          </Box>
        ))}
      </>
    );
  };

  const TableBody = () => (
    <SimpleGrid
      gridTemplateColumns={`500px repeat(${numberOfEnvironments}, 150px)`}
    >
      <SchemaNamesColumn />
      <EnvironmentSchemaVersionsColumn />
    </SimpleGrid>
  );

  if (isSmallerThan900) {
    return <EmptyState type={EmptyStateType.VIEW_NOT_AVAILABLE_ON_MOBILE} />;
  }

  return (
    <>
      <HeaderActions />
      {loading ? (
        <LoadingAnimation />
      ) : noEnvironmentsFound ? (
        <EmptyState type={EmptyStateType.NO_ENVIRONMENTS_IN_VERSIONS} />
      ) : schemaVersions.length === 0 ? (
        <EmptyState type={EmptyStateType.NO_SCHEMAS_IN_VERSIONS} />
      ) : (
        <Flex flexDirection="column">
          <TableHeader />
          <TableBody />
        </Flex>
      )}
    </>
  );
};

export default VersionsOverview;
