import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import LoadingAnimation from "../components/LoadingAnimation";
import { useAuthStore } from "../features/auth/store";
import { useTenants } from "../features/tenants/api/getTenants";
import { useTenantStore } from "../features/tenants/store";
import { ITenant } from "../features/tenants/types";

const ActiveTenantRoute = () => {
  const ldClient = useLDClient();
  const [isLdFlagLoaded, setIsLdFlagsLoaded] = useState(false);
  const userState = useAuthStore(useShallow((state) => state));
  const { user, isAdmin } = userState;
  const [activeTenant, setAvailableTenants, availableTenants, setActiveTenant] =
    useTenantStore(
      useShallow(
        ({
          activeTenant,
          setAvailableTenants,
          availableTenants,
          setActiveTenant
        }) => [
          activeTenant,
          setAvailableTenants,
          availableTenants,
          setActiveTenant
        ]
      )
    );

  const { data: tenants, isLoading } = useTenants({
    tenantIds: user?.tenantIds
  });

  useEffect(() => {
    if (tenants) {
      setAvailableTenants(tenants);
    }
  }, [tenants, setAvailableTenants]);

  useEffect(() => {
    if (availableTenants.length > 0 && !activeTenant.id) {
      // only set active tenant if not already set
      const active = getActiveTenant(availableTenants);
      setActiveTenant(userState, active, true);
    }
  }, [availableTenants, setActiveTenant, userState, activeTenant]);

  useEffect(() => {
    if (!user?.id || !ldClient) {
      return;
    }

    if (user.tenantIds.length === 0) {
      setIsLdFlagsLoaded(true);
    }

    if (!activeTenant.id) {
      return;
    }
    ldClient
      .identify({
        kind: "multi",
        user: {
          key: user.id,
          name: `${user.firstName ?? "unknown"} ${user.lastName ?? "unknown"}`,
          isAdmin: isAdmin
        },
        tenant: {
          key: activeTenant.id,
          name: activeTenant.name,
          routesConfig: activeTenant.routesConfig,
          javascriptConfig: activeTenant.javascriptSchemaFormatConfig,
          plan: activeTenant.plan
        }
      })
      .then(() => setIsLdFlagsLoaded(true))
      .catch(console.error);
  }, [user, ldClient, activeTenant, isAdmin]);

  if (isLoading || !isLdFlagLoaded) {
    return <LoadingAnimation></LoadingAnimation>;
  }

  if (!tenants?.length) {
    return <Navigate to="/no-tenants"></Navigate>;
  }

  return <Outlet></Outlet>;
};

const getActiveTenant = (
  availableTenants: ITenant[],
  isAdmin = false
): ITenant => {
  if (isAdmin) {
    const tenantString = window.localStorage.getItem("enterspeed-tenant");

    const tenantFromLocalStorage = tenantString
      ? (JSON.parse(tenantString) as ITenant)
      : null;
    if (tenantFromLocalStorage) {
      return tenantFromLocalStorage;
    }
  }

  const current = getCurrentTenantSetInLocalStorage(availableTenants);
  if (current) {
    return current;
  }

  const [activeTenant] = availableTenants;
  return activeTenant;
};

function getCurrentTenantSetInLocalStorage(tenants: ITenant[]) {
  return tenants.find(
    (tenant) =>
      tenant.id.idValue === window.localStorage.getItem("enterspeed-tenant-id")
  );
}

export default ActiveTenantRoute;
