import { SchemaFormat, SchemaType } from "../types";

export function schemaBoilerplateWithPreselectedSource(
  type: SchemaType,
  format: SchemaFormat,
  sourceGroup: string,
  sourceEntityTypes: string[] | null
) {
  const boilerplatePicker: {
    [key in SchemaFormat]: { [type in SchemaType]: string | undefined };
  } = {
    javascript: {
      normal: schemasWithSourceEntityTypesJavaScript(
        sourceGroup,
        sourceEntityTypes
      ),
      partial: undefined,
      collection: collectionSchemasWithSourceEntityTypesJavaScript(
        sourceGroup,
        sourceEntityTypes
      )
    },
    json: {
      normal: schemasWithSourceEntityTypesJson(sourceGroup, sourceEntityTypes),
      partial: undefined,
      collection: collectionSchemasWithSourceEntityTypesJson(
        sourceGroup,
        sourceEntityTypes
      )
    }
  };
  return boilerplatePicker[format][type];
}

export function schemaBoilerplateWithoutPreselectedSource(
  type: SchemaType,
  format: SchemaFormat
) {
  const boilerplatePicker: {
    [key in SchemaFormat]: { [type in SchemaType]: string | undefined };
  } = {
    javascript: {
      normal: defaultSourceGroupsFullSchemaJavaScript,
      partial: defaultPartialSchemaJavaScript,
      collection: defaultSourceGroupsCollectionSchemaJavaScript
    },
    json: {
      normal: defaultSourceGroupsFullSchema,
      partial: defaultPartialSchema,
      collection: defaultSourceGroupsCollectionSchema
    }
  };
  return boilerplatePicker[format][type];
}

export const defaultSourceGroupsFullSchemaJavaScript = `/** @type {Enterspeed.FullSchema} */
export default {
  triggers: function(context) {
    // Example that triggers on 'mySourceEntityType' in 'mySourceGroupAlias', adjust to match your own values
    // See documentation for triggers here: https://docs.enterspeed.com/reference/js/triggers
    context.triggers('mySourceGroupAlias', ['mySourceEntityType'])
  },
  routes: function(sourceEntity, context) {
    // Example that generates a handle with the value of 'my-handle' to use when fetching the view from the Delivery API
    // See documentation for routes here: https://docs.enterspeed.com/reference/js/routes
    context.handle('my-handle')
  },
  properties: function (sourceEntity, context) {
    // Example that returns all properties from the source entity to the view
    // See documentation for properties here: https://docs.enterspeed.com/reference/js/properties
    return sourceEntity.properties
  }
}`;

export const defaultSourceGroupsCollectionSchemaJavaScript = `/** @type {Enterspeed.FullSchema} */
export default {
  triggers: function(context) {
    // Example that triggers on 'mySourceEntityType' in 'mySourceGroupAlias', adjust to match your own values
    // See documentation for triggers here: https://docs.enterspeed.com/reference/js/triggers
    context.triggers('mySourceGroupAlias', ['mySourceEntityType'])
  },
  routes: function(sourceEntity, context) {
    // Example that generates a handle with the value of 'my-handle' to use when fetching the view from the Delivery API
    // See documentation for routes here: https://docs.enterspeed.com/reference/js/routes
    context.handle('my-handle')
  },
  items: function (sourceEntity, context) {
    // Example that returns a list of all children of the source entity
    // See documentation for items here: https://docs.enterspeed.com/reference/js/items
    return context.reference('childSchema').children();
  }
}`;

export const defaultPartialSchemaJavaScript = `/** @type {Enterspeed.PartialSchema} */
export default {
  properties: function (input, context) {
    // Example that returns all properties from the input object to the view
    // See documentation for properties here: https://docs.enterspeed.com/reference/js/properties
    return input
  }
}`;

export const defaultSourceGroupsFullSchema = `{
  "triggers": {},
  "properties": {}
}`;

export const defaultSourceGroupsCollectionSchema = `{
  "triggers": {},
  "items": {}
}`;

export const defaultPartialSchema = `{
  "properties": {}
}`;

export const schemasWithSourceEntityTypesJson = (
  sourceGroup: string,
  sourceEntityTypes: string[] | null
) => {
  if (!sourceEntityTypes) {
    return `{
      "triggers": {
        "${sourceGroup}": [ 
        ]
      },
      "route": {
        "handles": [
          "my-handle"
        ]
      },
      "properties": {
      "allContent": {
        "*": "p"
      }
    }
    }`;
  } else {
    return `{
    "triggers": {
      "${sourceGroup}": [
        ${sourceEntityTypes
          .map((sourceEntityType) => `"${sourceEntityType}"`)
          .join(", ")}
      ]
    },
    "route": {
      "handles": [
        "my-handle"
      ]
    },
    "properties": {
    "allContent": {
			"*": "p"
		}
  }
  }`;
  }
};

export const collectionSchemasWithSourceEntityTypesJson = (
  sourceGroup: string,
  sourceEntityTypes: string[] | null
) => {
  if (!sourceEntityTypes) {
    return `{
      "triggers": {
        "${sourceGroup}": [ 
        ]
      },
      "route": {
        "handles": [
          "my-handle"
        ]
      },
      "items": {
      }
    }`;
  } else {
    return `{
    "triggers": {
      "${sourceGroup}": [
        ${sourceEntityTypes
          .map((sourceEntityType) => `"${sourceEntityType}"`)
          .join(", ")}
      ]
    },
    "route": {
      "handles": [
        "my-handle"
      ]
    },
    "items": {
		}
  }`;
  }
};

export const schemasWithSourceEntityTypesJavaScript = (
  sourceGroup: string,
  sourceEntityTypes: string[] | null
) => {
  if (!sourceEntityTypes) {
    return `/** @type {Enterspeed.FullSchema} */
export default {
  triggers: function(context) {
    // Example that triggers on 'mySourceEntityType' in your source group, adjust to match your own source entity types
    // See documentation for triggers here: https://docs.enterspeed.com/reference/js/triggers
    context.triggers('${sourceGroup}', ['mySourceEntityType'])
  },
  routes: function(sourceEntity, context) {
    // Example that generates a handle with the value of 'my-handle' to use when fetching the view from the Delivery API
    // See documentation for routes here: https://docs.enterspeed.com/reference/js/routes
    context.handle('my-handle')
  },
  properties: function (sourceEntity, context) {
    // Example that returns all properties from the source entity to the view
    // See documentation for properties here: https://docs.enterspeed.com/reference/js/properties
    return sourceEntity.properties
  }
}`;
  } else {
    const sourceEntityTypesString = sourceEntityTypes
      .map((sourceEntityType) => `'${sourceEntityType}'`)
      .join(", ");

    return `/** @type {Enterspeed.FullSchema} */
export default {
  triggers: function(context) {
    // A trigger that triggers on your source entity type(s) in your source group
    // See documentation for triggers here: https://docs.enterspeed.com/reference/js/triggers
    context.triggers('${sourceGroup}', [${sourceEntityTypesString}])
  },
  routes: function(sourceEntity, context) {
    // Example that generates a handle with the value of 'my-handle' to use when fetching the view from the Delivery API
    // See documentation for routes here: https://docs.enterspeed.com/reference/js/routes
    context.handle('my-handle')
  },
  properties: function (sourceEntity, context) {
    // Example that returns all properties from the source entity to the view
    // See documentation for properties here: https://docs.enterspeed.com/reference/js/properties
    return sourceEntity.properties
  }
}`;
  }
};

export const collectionSchemasWithSourceEntityTypesJavaScript = (
  sourceGroup: string,
  sourceEntityTypes: string[] | null
) => {
  if (!sourceEntityTypes) {
    return `/** @type {Enterspeed.CollectionSchema} */
export default {
  triggers: function(context) {
    // Example that triggers on 'mySourceEntityType' in your source group, adjust to match your own source entity types
    // See documentation for triggers here: https://docs.enterspeed.com/reference/js/triggers
    context.triggers('${sourceGroup}', ['mySourceEntityType'])
  },
  routes: function(sourceEntity, context) {
    // Example that generates a handle with the value of 'my-handle' to use when fetching the view from the Delivery API
    // See documentation for routes here: https://docs.enterspeed.com/reference/js/routes
    context.handle('my-handle')
  },
  items: function (sourceEntity, context) {
    // Example that returns a list of all children of the source entity
    // See documentation for items here: https://docs.enterspeed.com/reference/js/items
    return context.reference('childSchema').children();
  }
}`;
  } else {
    const sourceEntityTypesString = sourceEntityTypes
      .map((sourceEntityType) => `'${sourceEntityType}'`)
      .join(", ");

    return `/** @type {Enterspeed.CollectionSchema} */
export default {
  triggers: function(context) {
    // A trigger that triggers on your source entity type(s) in your source group
    // See documentation for triggers here: https://docs.enterspeed.com/reference/js/triggers
    context.triggers('${sourceGroup}', [${sourceEntityTypesString}])
  },
  routes: function(sourceEntity, context) {
    // Example that generates a handle with the value of 'my-handle' to use when fetching the view from the Delivery API
    // See documentation for routes here: https://docs.enterspeed.com/reference/js/routes
    context.handle('my-handle')
  },
  items: function (sourceEntity, context) {
    // Example that returns a list of all children of the source entity
    // See documentation for items here: https://docs.enterspeed.com/reference/js/items
    return context.reference('childSchema').children();
  }
}`;
  }
};
