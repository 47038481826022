import {
  Button,
  forwardRef,
  Icon,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useEffect, useState } from "react";
import { getEnvironmentOption } from "../../../helpers/getEnvironmentOptions";
import { getSchemaOptionById } from "../../../helpers/getSchemaAliasOptions";
import { getSourceOption } from "../../../helpers/getSourceOptions";
import { useEnvironments } from "../../environments/api/getEnvironments";
import { useSchemas } from "../../schemas/api/getSchemas";
import { useSourceGroups } from "../../source-groups/api/getSourceGroups";
import { findByValue } from "../helpers/conditionFields";
import {
  IFieldFrontendCondition,
  IFrontendCondition,
  IMultiValueFrontendCondition,
  InputType,
  isFieldFrontendCondition,
  ISingleValueFrontendCondition,
  isMultiValueFrontendCondition,
  isNotEqualCondition,
  isSingleValueFrontendCondition
} from "../types";
interface IConditionFieldWithValues {
  inputType: InputType;
  values: string[];
}

function getConditionFieldWithValue(
  condition: IFrontendCondition
): IConditionFieldWithValues | null {
  if (!isFieldFrontendCondition(condition)) {
    return null;
  }
  const fieldCondition = condition;
  const field = findByValue(fieldCondition.field);
  if (!field) {
    return null;
  }

  if (isMultiValueFrontendCondition(fieldCondition)) {
    return {
      inputType: field.inputType,
      values: fieldCondition.values
    } as IConditionFieldWithValues;
  }

  const singleValueFrontendCondition =
    fieldCondition as ISingleValueFrontendCondition;
  return {
    inputType: field.inputType,
    values: [singleValueFrontendCondition.value]
  } as IConditionFieldWithValues;
}

const LogConditionButton = forwardRef(
  (
    {
      condition,
      onEdit,
      onDelete
    }: { condition: IFrontendCondition; onEdit(): void; onDelete(): void },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { data: dataSources } = useSourceGroups();
    const { data: dataEnvironments } = useEnvironments();
    const { data: dataSchemas } = useSchemas();
    const [displayValues, setDisplayValues] = useState<{
      [id: string]: string | undefined | null;
    }>({});
    let displayText: ReactNode = <></>;

    useEffect(() => {
      const fieldWithValue = getConditionFieldWithValue(condition);
      if (dataSources && fieldWithValue?.inputType === "SOURCE_PICKER") {
        const updatedDisplayValues = {
          ...displayValues
        };
        fieldWithValue.values.forEach((value) => {
          const sourceOption = getSourceOption(dataSources, value, "FULL");
          updatedDisplayValues[value] = sourceOption?.label;
        });
        setDisplayValues(updatedDisplayValues);
      }
    }, [dataSources, condition]);

    useEffect(() => {
      const fieldWithValue = getConditionFieldWithValue(condition);
      if (
        dataEnvironments &&
        fieldWithValue?.inputType === "ENVIRONMENT_PICKER"
      ) {
        const updatedDisplayValues = {
          ...displayValues
        };
        fieldWithValue.values.forEach((value) => {
          const environmentOption = getEnvironmentOption(
            dataEnvironments,
            value,
            "FULL"
          );
          updatedDisplayValues[value] = environmentOption?.label;
        });
        setDisplayValues(updatedDisplayValues);
      }
    }, [dataEnvironments, condition]);

    useEffect(() => {
      const fieldWithValue = getConditionFieldWithValue(condition);
      if (
        dataSchemas &&
        fieldWithValue?.inputType === "MAPPING_SCHEMA_PICKER_BY_ID"
      ) {
        const updatedDisplayValues = {
          ...displayValues
        };
        fieldWithValue.values.forEach((value) => {
          const environmentOption = getSchemaOptionById(
            dataSchemas,
            value,
            "FULL"
          );
          updatedDisplayValues[value] = environmentOption?.label;
        });
        setDisplayValues(updatedDisplayValues);
      }
    }, [dataSchemas, condition]);

    const getOperatorDisplay = (
      fieldCondition: IFieldFrontendCondition
    ): ReactNode | null => {
      if (isMultiValueFrontendCondition(fieldCondition)) {
        return (
          <Text as="span" mr={1.5}>
            : is one of{" "}
          </Text>
        );
      }

      return (
        <Text as="span" mr={1.5}>
          :
        </Text>
      );
    };

    const getOperatorPrefix = (
      fieldCondition: IFieldFrontendCondition
    ): ReactNode | null => {
      if (isNotEqualCondition(fieldCondition)) {
        return (
          <Text as="span" mr={1.5} color={"red.500"}>
            NOT
          </Text>
        );
      }

      return null;
    };

    const getButtonDisplayText = (
      fieldCondition: IFieldFrontendCondition
    ): ReactNode => {
      const values = isSingleValueFrontendCondition(fieldCondition)
        ? [fieldCondition.value]
        : [...(fieldCondition as IMultiValueFrontendCondition).values];
      const field =
        findByValue(fieldCondition.field)?.label ?? fieldCondition.field;
      return (
        <>
          {getOperatorPrefix(fieldCondition)}
          {field}
          {getOperatorDisplay(fieldCondition)}
          {values.map((value, index) => (
            <Text
              mr={1}
              as="span"
              color={"green.500"}
              key={`${fieldCondition.id};${value};${index}`}
            >
              {displayValues[value] ?? value}
              {index < values.length - 1 ? <Text as="span">, </Text> : null}
            </Text>
          ))}
        </>
      );
    };

    displayText = getButtonDisplayText(condition as IFieldFrontendCondition);
    return (
      <Text
        ref={ref}
        onClick={onEdit}
        as="span"
        role="group"
        fontSize="sm"
        fontWeight={500}
        overflowWrap={"break-word"}
        border={"1px"}
        borderColor={"gray.300"}
        borderRadius="base"
        backgroundColor={useColorModeValue("white", "gray.900")}
        cursor={"pointer"}
        pl={2.5}
        pr={1}
        py={1}
        _hover={{
          borderColor: useColorModeValue("gray.400", "gray.200"),
          textDecoration: "underline",
          background: useColorModeValue("gray.50", "gray.800"),
          transform: "border-color background-color",
          transitionDuration: "0.2s",
          transitionTimingFunction: "ease-in-out"
        }}
      >
        {displayText}
        <Button
          onClick={onDelete}
          aria-label="Remove filter"
          variant={"ghost"}
          colorScheme="red"
          size="xs"
          px={0}
          mx={0}
          mb={0.25}
        >
          <Icon color="red" as={FontAwesomeIcon} icon={faClose}></Icon>
        </Button>
      </Text>
    );
  }
);

export default LogConditionButton;
