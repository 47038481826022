import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { TENANTS } from "../../../constants/queryKey";
import { TenantService } from "../tenants.service";
import { ITenant } from "../types";

export const getTenants = async (
  tenantGuids?: string[]
): Promise<ITenant[]> => {
  if (!tenantGuids || tenantGuids.length === 0) {
    return [];
  }
  const tenantService = container.resolve(TenantService);
  return tenantService.getByIds(tenantGuids);
};

export const useTenants = ({ tenantIds }: { tenantIds?: string[] }) => {
  return useQuery({
    queryKey: [TENANTS, tenantIds],
    queryFn: () => {
      const tenantGuids = tenantIds?.map((tenantId) =>
        tenantId.replace("gid://Tenant/", "")
      );
      return getTenants(tenantGuids);
    }
  });
};
