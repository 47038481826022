import {
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer
} from "@chakra-ui/react";
import { useState } from "react";
import useMixPanel from "../../../mixpanel/useMixPanel";
import { useCreateInviteTenantUser } from "../api/createInviteTenantUser";
import { useInputColors } from "../hooks/colors";
import { useTenantStore } from "../store";
import { IInviteUserModalProps } from "../types";

const TenantInviteUserModal = ({
  isOpen,
  onClose,
  addExistingUser
}: IInviteUserModalProps) => {
  const [email, setEmail] = useState<string>();
  const [message, setMessage] = useState<string>();

  const { bg } = useInputColors();
  const { bg: modalBg } = useInputColors();

  const createInviteTenantUser = useCreateInviteTenantUser();
  const mixpanel = useMixPanel();
  const activeTenant = useTenantStore(({ activeTenant }) => activeTenant);

  const handleInviteClick = () => {
    if (email) {
      createInviteTenantUser.mutate({
        payload: {
          inviteeEmail: email,
          message: message || ""
        }
      });
      mixpanel.track("inviteSent", {
        inviteeEmail: email,
        tenantName: activeTenant.name,
        tenantId: activeTenant.id
      });
      onClose();
      return;
    }
    throw new Error("No email provided");
  };

  const clearStateOnClose = () => {
    setEmail("");
    setMessage("");
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        onCloseComplete={clearStateOnClose}
      >
        <ModalOverlay>
          <ModalContent bg={modalBg} p="4">
            <ModalHeader>
              Invite someone to join {activeTenant.name}
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <FormLabel>E-mail</FormLabel>

              <Input
                autoFocus
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                bg={bg}
              />
              {!addExistingUser && (
                <>
                  <Spacer my={4} />
                  <FormLabel>Message</FormLabel>
                  <Input
                    value={message}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setMessage(e.target.value)
                    }
                    bg={bg}
                  />
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleInviteClick}
                ml={3}
                isDisabled={!email}
              >
                Invite
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default TenantInviteUserModal;
