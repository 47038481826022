import "reflect-metadata";

import {
  Box,
  Button,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Progress,
  Text
} from "@chakra-ui/react";
import { faEllipsisV, faRemove } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import ContentBox from "./components/ContentBox";
import MetaTitle from "./components/MetaTitle";
import DeliveryApiMetrics from "./components/Tiles/DeliveryApiMetricsTile";
import DontGetStuckTile from "./components/Tiles/DontGetStuckTile";
import NewsTile from "./components/Tiles/NewsTile";
import StatsDataStorageTile from "./components/Tiles/StatsDataStorageTile";
import StatsDeliveryApiRequestsTile from "./components/Tiles/StatsDeliveryApiRequestsTile";
import StatsQueueSizeTile from "./components/Tiles/StatsQueueSizeTile";
import StatsSourceEntitiesTile from "./components/Tiles/StatsSourceEntitiesTile";
import { useInvites } from "./features/account/api/getInvites";
import { useAuthStore } from "./features/auth/store";
import { useEnvironments } from "./features/environments/api/getEnvironments";
import PlatformHealthTile from "./features/feedback/components/TenantHealth";
import GettingStartedOverview from "./features/getting-started/GettingStartedOverview";
import useDeliveryRequestsMetrics from "./features/metrics/api/useDeliveryRequestsMetrics";
import useEnterspeedDeliveryApiResponseTimeMetrics from "./features/metrics/api/useEnterspeedDeliveryApiResponseTimeMetrics";
import useEnterspeedDeliveryApiSlaMetrics from "./features/metrics/api/useEnterspeedDeliveryApiSlaMetrics";
import useQueueJobsMetrics from "./features/metrics/api/useQueuedJobsMetrics";
import useSourceEntitiesMetrics from "./features/metrics/api/useSourceEntitiesMetrics";
import useHomeStore from "./useHomeStore";

export default function Home() {
  const user = useAuthStore((state) => state.user);

  const { data: invitesData, isRefetching } = useInvites();

  const sourceEntitiesMetrics = useSourceEntitiesMetrics();
  const deliveryRequestsMetrics = useDeliveryRequestsMetrics();
  const queuedJobs = useQueueJobsMetrics();
  const deliveryApiResponseTimeMetrics =
    useEnterspeedDeliveryApiResponseTimeMetrics();
  const deliveryApiSlaMetrics = useEnterspeedDeliveryApiSlaMetrics();
  const environments = useEnvironments();

  const flags = useFlags();

  const errorTile = flags.errorTile as boolean;

  const [removeTile, showTile, removedTiles] = useHomeStore()(
    useShallow((state) => [
      state.removeTile,
      state.showTile,
      state.removedTiles
    ])
  );

  const tileShortNames = [
    { key: "statsSourceEntities", name: "Source entities" },
    { key: "statsDeliveryApiRequests", name: "Delivery requests" },
    { key: "statsDataStorage", name: "Data storage" },
    { key: "statsQueueSize", name: "Queued jobs" },
    { key: "deliveryApiMetrics", name: "Delivery API" },
    { key: "news", name: "Feature news" },
    { key: "gettingStarted", name: "Getting started" },
    { key: "dontGetStuck", name: "Don't get stuck" },
    { key: "tenantHealth", name: "Tenant health" }
  ];

  const removeTileMenu = (tileName: string) => {
    return (
      <Box
        style={{
          opacity: showRemoveTileMenu === tileName ? 1 : 0,
          transition: "opacity 0.25s"
        }}
      >
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<Icon as={FontAwesomeIcon} icon={faEllipsisV}></Icon>}
            variant={"ghost"}
          ></MenuButton>
          <MenuList>
            <MenuItem
              icon={<Icon as={FontAwesomeIcon} icon={faRemove}></Icon>}
              onClick={() => removeTile(tileName)}
            >
              Hide
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    );
  };

  const [showRemoveTileMenu, setShowRemoveTileMenu] = useState<string | null>();

  return (
    <>
      <MetaTitle title="Overview" />
      <Box
        width={"100%"}
        sx={{
          containerType: "inline-size",
          containerName: "grid-container",
          "@container grid-container (min-width: 0px)": {
            ".dashboard-grid": {
              gridTemplateColumns: "repeat(1, minmax(0,1fr))"
            },
            ".col1": { gridColumn: "span 1" },
            ".col1-2": { gridColumn: "span 1" },
            ".col2": { gridColumn: "span 1" },
            ".col3": { gridColumn: "span 1" },
            ".col4": { gridColumn: "span 1" },
            ".col5": { gridColumn: "span 1" }
          },
          "@container grid-container (min-width: 432px)": {
            ".dashboard-grid": {
              gridTemplateColumns: "repeat(2, minmax(0,1fr))"
            },
            ".col1": { gridColumn: "span 1" },
            ".col1-2": { gridColumn: "span 2" },
            ".col2": { gridColumn: "span 2" },
            ".col3": { gridColumn: "span 2" },
            ".col4": { gridColumn: "span 2" },
            ".col5": { gridColumn: "span 2" }
          },
          "@container grid-container (min-width: 1200px)": {
            ".dashboard-grid": {
              gridTemplateColumns: "repeat(4, minmax(0,1fr))"
            },
            ".col1": { gridColumn: "span 1" },
            ".col1-2": { gridColumn: "span 1" },
            ".col2": { gridColumn: "span 2" },
            ".col3": { gridColumn: "span 3" },
            ".col4": { gridColumn: "span 4" },
            ".col5": { gridColumn: "span 4" }
          },
          "@container grid-container (min-width: 2250px)": {
            ".dashboard-grid": {
              gridTemplateColumns: "repeat(5, minmax(0,1fr))"
            },
            ".col1": { gridColumn: "span 1" },
            ".col1-2": { gridColumn: "span 1" },
            ".col2": { gridColumn: "span 2" },
            ".col3": { gridColumn: "span 3" },
            ".col4": { gridColumn: "span 4" },
            ".col5": { gridColumn: "span 5" }
          }
        }}
      >
        <Grid
          width="100%"
          gap={4}
          grid-auto-flow="column"
          className="dashboard-grid"
        >
          <GridItem className="col5">
            <Box display={"flex"} justifyContent={"space-between"}>
              <Text fontSize="24px" fontWeight="semibold">{`👋 Hi ${
                user?.firstName ?? ""
              }, welcome to Enterspeed`}</Text>{" "}
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<Icon as={FontAwesomeIcon} icon={faEllipsisV}></Icon>}
                  variant={"ghost"}
                ></MenuButton>
                <MenuList>
                  <MenuGroup title="Add tiles">
                    {removedTiles.map((removedTile) => {
                      return (
                        <MenuItem
                          key={`removedTile-${removedTile}`}
                          onClick={() => showTile(removedTile)}
                        >
                          {tileShortNames.find(
                            (element) => element.key === removedTile
                          )?.name ?? removedTile}
                        </MenuItem>
                      );
                    })}
                    {removedTiles.length === 0 && (
                      <Text ml={3} color={"gray.400"}>
                        - All tiles are already added
                      </Text>
                    )}
                  </MenuGroup>
                </MenuList>
              </Menu>
            </Box>
          </GridItem>
          {invitesData && invitesData.length !== 0 && (
            <GridItem className="col5">
              <ContentBox title="Pending invitations">
                <Text>
                  {`You have ${invitesData.length} pending ${
                    invitesData.length > 1 ? "invitations" : "invitation"
                  } `}
                  <Button
                    as={NavLink}
                    variant={"link"}
                    to="/account/invitations"
                  >
                    Go to invitation page
                  </Button>
                  {" to join an existing tenant."}
                </Text>
              </ContentBox>
              <Box>
                {isRefetching && <Progress size="xs" isIndeterminate />}
              </Box>
            </GridItem>
          )}
          {user?.multipleEmailsWarning && (
            <GridItem className="col5">
              <ContentBox title="⚠️ Duplicate user email">
                <Text>
                  We registered multiple users with the same email address (
                  {user?.email}). This can happen if you have a email and
                  password user as well as a Microsoft Entra ID user with the
                  same email.
                </Text>
                <Text>
                  Reach out to us in order to merge the two users and delete one
                  of them.
                </Text>
              </ContentBox>
            </GridItem>
          )}
          {!removedTiles.includes("statsSourceEntities") && (
            <GridItem
              className="col1"
              onMouseEnter={() => {
                setShowRemoveTileMenu("statsSourceEntities");
              }}
              onMouseLeave={() => {
                setShowRemoveTileMenu(null);
              }}
            >
              <StatsSourceEntitiesTile
                sourceEntitiesMetrics={sourceEntitiesMetrics.data}
                removeTileMenu={removeTileMenu("statsSourceEntities")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("statsDeliveryApiRequests") && (
            <GridItem
              className="col1"
              onMouseEnter={() => {
                setShowRemoveTileMenu("statsDeliveryApiRequests");
              }}
              onMouseLeave={() => {
                setShowRemoveTileMenu(null);
              }}
            >
              <StatsDeliveryApiRequestsTile
                deliveryStats={deliveryRequestsMetrics.data}
                removeTileMenu={removeTileMenu("statsDeliveryApiRequests")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("statsDataStorage") && (
            <GridItem
              className="col1"
              onMouseEnter={() => {
                setShowRemoveTileMenu("statsDataStorage");
              }}
              onMouseLeave={() => {
                setShowRemoveTileMenu(null);
              }}
            >
              <StatsDataStorageTile
                sourceEntityMetrics={sourceEntitiesMetrics.data}
                removeTileMenu={removeTileMenu("statsDataStorage")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("statsQueueSize") && (
            <GridItem
              className="col1"
              onMouseEnter={() => {
                setShowRemoveTileMenu("statsQueueSize");
              }}
              onMouseLeave={() => {
                setShowRemoveTileMenu(null);
              }}
            >
              <StatsQueueSizeTile
                queuedJobsDto={queuedJobs.data}
                environments={environments.data}
                removeTileMenu={removeTileMenu("statsQueueSize")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("deliveryApiMetrics") && (
            <GridItem
              className="col1-2"
              onMouseEnter={() => {
                setShowRemoveTileMenu("deliveryApiMetrics");
              }}
              onMouseLeave={() => {
                setShowRemoveTileMenu(null);
              }}
            >
              <DeliveryApiMetrics
                responseTimes={deliveryApiResponseTimeMetrics.data}
                sla={deliveryApiSlaMetrics.data}
                removeTileMenu={removeTileMenu("deliveryApiMetrics")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("news") && (
            <GridItem
              className="col3"
              onMouseEnter={() => {
                setShowRemoveTileMenu("news");
              }}
              onMouseLeave={() => {
                setShowRemoveTileMenu(null);
              }}
            >
              <NewsTile removeTileMenu={removeTileMenu("news")} />
            </GridItem>
          )}
          {errorTile && !removedTiles.includes("tenantHealth") && (
            <GridItem
              className="col2"
              onMouseEnter={() => {
                setShowRemoveTileMenu("tenantHealth");
              }}
              onMouseLeave={() => {
                setShowRemoveTileMenu(null);
              }}
            >
              <PlatformHealthTile
                removeTileMenu={removeTileMenu("tenantHealth")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("gettingStarted") && (
            <GridItem
              className="col1"
              onMouseEnter={() => {
                setShowRemoveTileMenu("gettingStarted");
              }}
              onMouseLeave={() => {
                setShowRemoveTileMenu(null);
              }}
            >
              <GettingStartedOverview
                removeTileMenu={removeTileMenu("gettingStarted")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("dontGetStuck") && (
            <GridItem
              className="col1"
              onMouseEnter={() => {
                setShowRemoveTileMenu("dontGetStuck");
              }}
              onMouseLeave={() => {
                setShowRemoveTileMenu(null);
              }}
            >
              <DontGetStuckTile
                removeTileMenu={removeTileMenu("dontGetStuck")}
              />
            </GridItem>
          )}
        </Grid>
      </Box>
    </>
  );
}
