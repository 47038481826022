import { container } from "tsyringe";
import { DeploymentService } from "../../deployment/deployment.service";

export const getSchemasFromEnvironment = async ({ id }: { id: string }) => {
  const deploymentService = container.resolve(DeploymentService);
  const {
    current: { schemas }
  } = await deploymentService.getDeploymentByEnvironment(id);
  return schemas;
};
