import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";

const DeleteTenantPage = () => {
  const title = "Delete tenant";
  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox title={title}>woop</ContentBox>
    </>
  );
};

export default DeleteTenantPage;
