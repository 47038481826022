import { GroupBase, Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import EnterspeedIdentifiableSelect from "../../../components/EnterspeedIdentifiableSelect";
import {
  getSourceOption,
  getSourceOptions
} from "../../../helpers/getSourceOptions";
import useReactEnterspeedIdentifiableSelectStyles from "../../../styles/react-enterspeed-identifiable-select-style";
import IEnterspeedIdentifiableSelectOption from "../../../types/identifiableSelectInput";
import { useSourceGroups } from "../../source-groups/api/getSourceGroups";

const SingleValueConditionSourcePicker = ({
  onSelect,
  value
}: {
  value: string | null;
  onSelect(value: string | null | undefined): void;
}) => {
  const { data: dataSources } = useSourceGroups();
  const sourceOptions = getSourceOptions(dataSources, "FULL");
  const reactSelectStyles = useReactEnterspeedIdentifiableSelectStyles();
  const [selectedSource, setSelectedSource] = useState<
    IEnterspeedIdentifiableSelectOption | undefined
  >();

  useEffect(() => {
    if (!dataSources) {
      return;
    }

    const dataSourceOption = value
      ? getSourceOption(dataSources, value, "FULL")
      : undefined;
    setSelectedSource(dataSourceOption);
  }, [dataSources, value]);

  const handleSourceFilter = (value: IEnterspeedIdentifiableSelectOption) => {
    setSelectedSource(value);
    onSelect(value?.value as string | null);
  };

  return (
    <Select<
      IEnterspeedIdentifiableSelectOption,
      true,
      GroupBase<IEnterspeedIdentifiableSelectOption>
    >
      size="sm"
      useBasicStyles
      chakraStyles={reactSelectStyles}
      colorScheme="brand"
      placeholder="Select source"
      options={sourceOptions}
      components={new EnterspeedIdentifiableSelect()}
      value={selectedSource}
      onChange={(value) => {
        return handleSourceFilter(
          value as unknown as IEnterspeedIdentifiableSelectOption
        );
      }}
    />
  );
};

export default SingleValueConditionSourcePicker;
