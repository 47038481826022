import { GroupBase, Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import EnterspeedIdentifiableSelect from "../../../components/EnterspeedIdentifiableSelect";
import {
  getEnvironmentOption,
  getEnvironmentOptions
} from "../../../helpers/getEnvironmentOptions";
import useReactEnterspeedIdentifiableSelectStyles from "../../../styles/react-enterspeed-identifiable-select-style";
import IEnterspeedIdentifiableSelectOption from "../../../types/identifiableSelectInput";
import { useEnvironments } from "../../environments/api/getEnvironments";

const SingleValueConditionEnvironmentPicker = ({
  onSelect,
  value
}: {
  value: string | null;
  onSelect(value: string | null | undefined): void;
}) => {
  const { data: dataEnvironments } = useEnvironments();
  const environmentOptions = getEnvironmentOptions(dataEnvironments, "FULL");
  const reactSelectStyles = useReactEnterspeedIdentifiableSelectStyles();
  const [selectedEnvironment, setSelectedEnvironment] = useState<
    IEnterspeedIdentifiableSelectOption | undefined
  >();

  useEffect(() => {
    if (!dataEnvironments) {
      return;
    }

    const dataEnvironmentOption = value
      ? getEnvironmentOption(dataEnvironments, value, "FULL")
      : undefined;
    setSelectedEnvironment(dataEnvironmentOption);
  }, [dataEnvironments, value]);

  const handleEnvironmentFilter = (
    value: IEnterspeedIdentifiableSelectOption
  ) => {
    setSelectedEnvironment(value);
    onSelect(value?.value as string | null);
  };

  return (
    <Select<
      IEnterspeedIdentifiableSelectOption,
      true,
      GroupBase<IEnterspeedIdentifiableSelectOption>
    >
      size="sm"
      useBasicStyles
      chakraStyles={reactSelectStyles}
      colorScheme="brand"
      placeholder="Select environment"
      options={environmentOptions}
      components={new EnterspeedIdentifiableSelect()}
      value={selectedEnvironment}
      onChange={(value) => {
        return handleEnvironmentFilter(
          value as unknown as IEnterspeedIdentifiableSelectOption
        );
      }}
    />
  );
};

export default SingleValueConditionEnvironmentPicker;
