import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { useShallow } from "zustand/react/shallow";
import { ENVIRONMENTS } from "../../../constants/queryKey";
import { QueryOptions } from "../../../types/api";
import { useTenantStore } from "../../tenants/store";
import { EnvironmentService } from "../environment.service";

export const getEnvironments = async (options?: QueryOptions) => {
  const environmentService = container.resolve(EnvironmentService);
  return environmentService.getAllEnvironments(options);
};

export const useEnvironments = (options?: QueryOptions) => {
  const { availableTenants } = useTenantStore(
    useShallow(({ availableTenants }) => ({
      availableTenants
    }))
  );
  return useQuery({
    queryKey: [ENVIRONMENTS, options?.tenantId],
    queryFn: () => getEnvironments(options),
    enabled: availableTenants.length !== 0
  });
};
