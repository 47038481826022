import { Button } from "@chakra-ui/react";
import { useContext, useEffect } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SidePanelContext } from "../../../context/SidePanelContext";
import { BulkDeployPanelContent } from "./BulkDeployPanelContent";

const BulkDeployPanel = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const {
    setOpen,
    setTitle,
    setContent,
    setCustomStartWidth,
    setCustomMinWidth
  } = useContext(SidePanelContext);

  useEffect(() => {
    if (searchParams?.get("sidePanel") === "bulkDeploy") {
      handleOpenSidePanel();
    }
  }, [searchParams]);

  const handleOpenSidePanel = () => {
    setTitle("Deploy changes");
    setContent(
      <BulkDeployPanelContent
        onCancel={() => {
          setOpen(false);
        }}
      />
    );
    setOpen(true);
    setCustomMinWidth(500);
    setCustomStartWidth(40);
  };

  const onClick = () => {
    setTitle("Deploy changes");
    setContent(
      <BulkDeployPanelContent
        onCancel={() => {
          setOpen(false);
        }}
      />
    );
    setOpen(true);
    setCustomStartWidth(40);
    setCustomMinWidth(500);
    const sp = new URLSearchParams(searchParams ?? undefined);
    sp.set("sidePanel", "bulkDeploy");
    if (pathname) {
      navigate(`${pathname}?${sp.toString()}`);
    }
  };

  return (
    <>
      <Button variant="primary" onClick={onClick}>
        Deploy schemas
      </Button>
    </>
  );
};

export default BulkDeployPanel;
