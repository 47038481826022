import {
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import { faRefresh } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { LatestAppPromptAction } from "./types";
import useLatestAppVersion from "./useLatestAppVersion";

const EnterspeedAppUpdatedToast = ({
  onUserAction
}: {
  onUserAction: (action: LatestAppPromptAction) => void;
}) => {
  const bgColor = useColorModeValue("brand.500", "brand.300");

  return (
    <Flex
      direction={"column"}
      gap={"1em"}
      color="white"
      p={3}
      bg={bgColor}
      borderRadius={"lg"}
      justify={"center"}
    >
      <Flex gap={".5rem"} justify={"space-between"} align={"center"}>
        <Image
          src={`${process.env.PUBLIC_URL}/favicon/favicon-32x32.png`}
        ></Image>
        <Heading size={"sm"}>
          A new version of Enterspeed app is available
        </Heading>
      </Flex>
      <Button
        rightIcon={<Icon as={FontAwesomeIcon} icon={faRefresh}></Icon>}
        variant={"subtle"}
        textColor={"white"}
        onClick={() => onUserAction("refresh")}
      >
        Click to refresh
      </Button>
    </Flex>
  );
};

const useLatestAppToast = () => {
  const toast = useToast();
  const toastId = "newer-app-version";

  const [currentVersion, setCurrentVersion] = useState<string>("");

  const handleUserAction = (action: LatestAppPromptAction) => {
    switch (action) {
      case "refresh":
        return window.location.reload();
      case "dismiss":
        return toast.close(toastId);
    }
  };

  const { data: latestVersion } = useLatestAppVersion();

  useEffect(() => {
    if (latestVersion) {
      setCurrentVersion(latestVersion);
    }
  }, [latestVersion]);

  if (!latestVersion) {
    return;
  }

  if (
    currentVersion &&
    latestVersion !== currentVersion &&
    !toast.isActive(toastId)
  ) {
    toast({
      id: toastId,
      position: "bottom-right",
      duration: null,
      isClosable: true,
      render: () => (
        <EnterspeedAppUpdatedToast onUserAction={handleUserAction} />
      )
    });
  }
};

export default useLatestAppToast;
