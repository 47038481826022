import {
  Box,
  Divider,
  Flex,
  Icon,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack
} from "@chakra-ui/react";
import {
  faCircleMinus,
  faCirclePlus,
  faEye
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatDateTime from "../../../helpers/formatDateTime";
import { fields } from "../helpers/conditionFields";
import { getDisplayName } from "../helpers/fieldDisplayNames";
import { IEnhancedLog, Operator } from "../types";
import { DisplayType, isPlaceholder } from "../types/interactivePlaceholders";
import CopyValueActionButton from "./CopyValueActionButton";
import InterpolatedField from "./InterpolatedField";
import InterpolatedText from "./InterpolatedText";
import LevelBadge from "./LevelBadge";
import LogErrors from "./LogErrors";

const LogPanel = ({
  row,
  filterValue,
  toggleColumn,
  displayType
}: {
  row: IEnhancedLog;
  displayType: DisplayType;
  filterValue: (operator: Operator, field: string, value: string) => void;
  toggleColumn: (field: string) => void;
}) => {
  const { timestamp, level, service, properties } = row;
  const ignoredPropertiesToDisplayInDetails = [
    "Errors",
    "Error",
    "ReleaseId",
    "Entity",
    "ActorIsImpersonating",
    "Action",
    "ActorType"
  ];
  const displayPropertyKeys = Object.keys(properties).filter(
    (f) => !ignoredPropertiesToDisplayInDetails.includes(f)
  );
  const filterablePropertyNames = displayPropertyKeys.filter(
    (propertyKey: string) => {
      return !!fields.find((f) => f.value === propertyKey);
    }
  );

  return (
    <Box fontSize="sm">
      <VStack alignItems="flex-start">
        <Text>
          <Text as="b">Time: </Text>
          {formatDateTime(timestamp)}
        </Text>
        <Text>
          <Text as="b" mr="1">
            Level:{" "}
          </Text>
          <LevelBadge level={level} />
        </Text>
        <Text>
          <Text as="b">Service: </Text>
          {service}
        </Text>
      </VStack>
      <Divider my={6} />
      <Flex direction={"column"} gap={7} fontSize="sm">
        <Box>
          <Text mb="2">
            <Text as="b">Message: </Text>
          </Text>
          <InterpolatedText
            text={
              row.messageTemplate === ""
                ? (row.properties.TeaserMessageTemplate as string)
                : row.messageTemplate
            }
            properties={row.properties}
            enhancements={row.enhancements}
            displayType={displayType}
          ></InterpolatedText>
        </Box>
        <LogErrors log={row}></LogErrors>
        <Box>
          <Text mb="2">
            <Text as="b">Details: </Text>
          </Text>
          <TableContainer whiteSpace={"wrap"}>
            <Table size="sm" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>Actions</Th>
                  <Th>Field</Th>
                  <Th>Value</Th>
                </Tr>
              </Thead>
              <Tbody>
                {displayPropertyKeys
                  .sort((a, b) => (a > b ? 1 : -1))
                  .map((key) => {
                    const isRecognizedPlaceholder = isPlaceholder(key);
                    const valueAsString = row.properties[key] as string;

                    return (
                      <Tr key={key}>
                        <Td>
                          <Flex direction={"row"} gap={0.5}>
                            <Tooltip label="Toggle column visibility">
                              <IconButton
                                onClick={() =>
                                  toggleColumn(`properties.${key}`)
                                }
                                size="xs"
                                variant="link"
                                aria-label="Toggle column visibility"
                                icon={
                                  <Icon as={FontAwesomeIcon} icon={faEye} />
                                }
                              />
                            </Tooltip>
                            {filterablePropertyNames.includes(key) ? (
                              <>
                                <Tooltip label="Filter for value">
                                  <IconButton
                                    onClick={() =>
                                      filterValue(
                                        "EQUAL",
                                        key,
                                        properties[key] as string
                                      )
                                    }
                                    size="xs"
                                    variant="link"
                                    aria-label="Filter for value"
                                    icon={
                                      <Icon
                                        as={FontAwesomeIcon}
                                        icon={faCirclePlus}
                                      />
                                    }
                                  />
                                </Tooltip>
                                <Tooltip label="Filter out value">
                                  <IconButton
                                    onClick={() =>
                                      filterValue(
                                        "NOT_EQUAL",
                                        key,
                                        properties[key] as string
                                      )
                                    }
                                    size="xs"
                                    variant="link"
                                    aria-label="Filter out value"
                                    icon={
                                      <Icon
                                        as={FontAwesomeIcon}
                                        icon={faCircleMinus}
                                      />
                                    }
                                  />
                                </Tooltip>
                              </>
                            ) : undefined}
                            <CopyValueActionButton value={valueAsString} />
                          </Flex>
                        </Td>
                        <Td>{getDisplayName(key)}</Td>
                        <Td>
                          {isRecognizedPlaceholder ? (
                            <InterpolatedField
                              displayType={displayType}
                              key={Math.random()}
                              input={key}
                              properties={row.properties}
                              enhancements={row.enhancements}
                              fallbackValue={valueAsString}
                            ></InterpolatedField>
                          ) : key === "Level" ? (
                            <LevelBadge
                              level={
                                valueAsString as
                                  | "Warning"
                                  | "Information"
                                  | "Error"
                              }
                            />
                          ) : (
                            <>{valueAsString}</>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Flex>
    </Box>
  );
};

export default LogPanel;
