import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import { faJsSquare } from "@fortawesome/free-brands-svg-icons";
import {
  faBracketsCurly,
  faCog,
  faCogs,
  faList,
  faLock,
  faLockOpen
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "chakra-react-select";

import { Field, FieldProps, Form, Formik } from "formik";

import { useRef, useState } from "react";

import {
  CreateSchemaModalRequest,
  ICreateSchemaModal,
  SchemaFormat,
  SchemaListModalType,
  SchemaType
} from "../types";
import {
  schemaBoilerplateWithoutPreselectedSource,
  schemaBoilerplateWithPreselectedSource
} from "../utils/schemaBoilerplate";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useShallow } from "zustand/react/shallow";
import RadioCards from "../../../components/form/RadioCards";
import HelpIcon from "../../../components/HelpIcon";
import MultiDropdown from "../../../components/MultiDropdown";
import camelize from "../../../helpers/camelize";
import { isRequired } from "../../../helpers/fieldIsRequired";
import {
  getSourceOption,
  getSourceOptions
} from "../../../helpers/getSourceOptions";
import useReactSelectStyles from "../../../styles/react-select-style";
import IRadioCardInput from "../../../types/radioCard";
import ISelectOption from "../../../types/selectInput";
import { useSourceEntityTypes } from "../../source-entities/api/getSourceEntityTypes";
import { useSourceGroups } from "../../source-groups/api/getSourceGroups";
import { useInputColors, useSummaryColors } from "../../tenants/hooks/colors";
import { useTenantStore } from "../../tenants/store";

const CreateSchemaModal = ({
  name,
  isOpen,
  onClose,
  onSubmit,
  onSuccess
}: ICreateSchemaModal) => {
  const { activeTenant } = useTenantStore(
    useShallow(({ activeTenant }) => ({ activeTenant }))
  );
  const initialRef = useRef(null);

  const flags = useFlags();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [aliasEditable, setAliasEditable] = useState(false);
  const [aliasChanged, setAliasChanged] = useState(false);
  const [selectedSource, setSelectedSource] = useState<string>();
  const [selectedSourceEntityTypes, setSelectedSourceEntityTypes] = useState<
    ISelectOption[]
  >([]);

  const clearStateOnClose = () => {
    setAliasEditable(false);
    setAliasChanged(false);
    setSelectedSource(undefined);
    setSelectedSourceEntityTypes([]);
  };

  const schemaTypeOptions: IRadioCardInput[] = [
    {
      label: "Full schema",
      icon: faCogs,
      value: "normal"
    },
    {
      label: "Partial schema",
      icon: faCog,
      value: "partial"
    }
  ];

  if (flags.collectionSchemas) {
    schemaTypeOptions.push({
      label: "Collection schema",
      icon: faList,
      value: "collection"
    });
  }

  const schemaFormatOptions: IRadioCardInput[] = [
    {
      label: "JavaScript",
      icon: faJsSquare,
      value: "javascript"
    },
    {
      label: "JSON",
      icon: faBracketsCurly,
      value: "json"
    }
  ].filter(
    (format) =>
      (format.value === "javascript" &&
        activeTenant.javascriptSchemaFormatConfig?.enabled) ||
      format.value === "json"
  );

  const { data: dataSourceGroups } = useSourceGroups();
  const { data: dataSourceEntityTypes } = useSourceEntityTypes(
    selectedSource as string
  );

  const getSchemaBoilerplate = (
    schemaType: SchemaType,
    schemaFormat: SchemaFormat
  ) => {
    if (!selectedSource) {
      return schemaBoilerplateWithoutPreselectedSource(
        schemaType,
        schemaFormat
      );
    }

    const matchingSourceGroup = dataSourceGroups?.find((sourceGroup) =>
      sourceGroup.sources.some(
        (sourceGroupSource) =>
          sourceGroupSource.source.id.sourceGuid === selectedSource
      )
    );

    if (!matchingSourceGroup) {
      return schemaBoilerplateWithoutPreselectedSource(
        schemaType,
        schemaFormat
      );
    }

    const mappedSourceEntityTypes: string[] = selectedSourceEntityTypes?.map(
      ({ value }) => value as string
    );

    return (
      schemaBoilerplateWithPreselectedSource(
        schemaType,
        schemaFormat,
        matchingSourceGroup.alias,
        mappedSourceEntityTypes
      ) ?? schemaBoilerplateWithoutPreselectedSource(schemaType, schemaFormat)
    );
  };

  const sourceEntityTypeOptions = () => {
    if (!dataSourceEntityTypes) {
      return [];
    }

    return dataSourceEntityTypes
      .map(
        (item) =>
          ({
            label: item,
            value: item
          } as ISelectOption)
      )
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  };

  const submit = async (values: {
    name: string;
    alias: string;
    format: SchemaFormat;
    type: SchemaType;
  }) => {
    if (!onSubmit) {
      return onSuccess(SchemaListModalType.CREATE);
    }

    setIsLoading(true);
    const schemaBoilerplate = getSchemaBoilerplate(
      values?.type,
      values?.format
    );
    const submissionResult = await onSubmit({
      format: values.format,
      viewHandle: values.alias,
      type: values.type,
      name: values.name,
      data: schemaBoilerplate
    } as CreateSchemaModalRequest);
    setIsLoading(false);
    if (submissionResult.valid) {
      onSuccess(
        SchemaListModalType.CREATE,
        submissionResult.mappingSchemaGuid,
        values.name
      );
    }
  };

  const { bg, disabled } = useInputColors();
  const { bg: summaryBg, hover } = useSummaryColors();
  const reactSelectStyles = useReactSelectStyles();

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
        size="4xl"
        onCloseComplete={clearStateOnClose}
      >
        <ModalOverlay>
          <ModalContent bg={useColorModeValue("gray.100", "gray.800")} p="4">
            <ModalHeader>Create new schema</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Text>
                Give your schema a name and an unique alias. Please note that
                the alias cannot be changed later on.
              </Text>
              <Alert status="info" mt="4" mb="8">
                <AlertIcon />
                <AlertTitle>Tip:</AlertTitle> You can organize your schemas into
                folders, up to two levels deep, by adding slashes (/) in the
                name.
              </Alert>

              <Formik
                initialValues={{
                  name: name ?? "",
                  alias: "",
                  format: activeTenant.javascriptSchemaFormatConfig.enabled
                    ? "javascript"
                    : "json",
                  type: "normal"
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={submit}
              >
                {({ setFieldValue, handleChange, values }) => {
                  return (
                    <Form autoComplete="off">
                      <SimpleGrid columns={2} spacing={4} mb={2}>
                        <Field
                          name="name"
                          validate={isRequired("Name is required")}
                        >
                          {({ field, form }: FieldProps) => (
                            <FormControl isInvalid={!!form.errors.name} mb="6">
                              <FormLabel fontSize="sm">Name</FormLabel>
                              <Input
                                data-1p-ignore
                                ref={initialRef}
                                id="name"
                                bg={bg}
                                {...field}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleChange(e);
                                  if (!aliasChanged) {
                                    let alias = e.target.value;
                                    if (alias.includes("/")) {
                                      alias = alias.substring(
                                        alias.lastIndexOf("/") + 1
                                      );
                                    }
                                    alias = camelize(alias);
                                    return setFieldValue("alias", alias);
                                  }
                                }}
                              />
                              <FormErrorMessage>
                                {form.errors.name?.toString()}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                        <Field
                          name="alias"
                          validate={isRequired("Alias is required")}
                        >
                          {({ field, form }: FieldProps) => (
                            <FormControl isInvalid={!!form.errors.alias} mb="6">
                              <FormLabel fontSize="sm">Alias</FormLabel>
                              <InputGroup size="md">
                                <Input
                                  isDisabled={!aliasEditable}
                                  bg={aliasEditable ? bg : disabled.bg}
                                  pr="4.5rem"
                                  {...field}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange(e);
                                    setAliasChanged(true);
                                  }}
                                />
                                <InputRightElement width="4.5rem">
                                  <Tooltip
                                    label={
                                      aliasEditable
                                        ? "Disable alias editing"
                                        : "Edit alias"
                                    }
                                  >
                                    <IconButton
                                      aria-label="Toggle edit alias"
                                      colorScheme="gray"
                                      icon={
                                        <Icon
                                          as={FontAwesomeIcon}
                                          icon={
                                            aliasEditable ? faLockOpen : faLock
                                          }
                                        />
                                      }
                                      onClick={() =>
                                        setAliasEditable(!aliasEditable)
                                      }
                                    />
                                  </Tooltip>
                                </InputRightElement>
                              </InputGroup>
                              <FormErrorMessage>
                                {form.errors.alias?.toString()}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </SimpleGrid>
                      {
                        <>
                          {schemaFormatOptions.length > 1 && (
                            <RadioCards
                              mb={6}
                              label="Schema format"
                              options={schemaFormatOptions}
                              help="Choose which format you want to write your schema in."
                              valueCallback={(value) => {
                                setFieldValue("format", value).catch(
                                  console.error
                                );
                                return value;
                              }}
                              selectedValue={values.format}
                            />
                          )}
                          <RadioCards
                            label="Schema type"
                            options={schemaTypeOptions}
                            help="Choose if you want a full schema or a partial schema. You can think of a partial schema as a reusable component/building block for your full schemas."
                            valueCallback={(value) => {
                              setFieldValue("type", value).catch(console.error);
                              return value;
                            }}
                            selectedValue={values.type}
                          />
                        </>
                      }
                      {values.type !== "partial" && (
                        <Box as="details" mt="8">
                          <Box
                            as="summary"
                            p="2"
                            mb="4"
                            bg={summaryBg}
                            fontWeight="semibold"
                            borderRadius="sm"
                            cursor="pointer"
                            _hover={{
                              bg: hover.bg
                            }}
                          >
                            Prefill your schema
                            <HelpIcon
                              size="sm"
                              label="You can select a data source to prefill your schema with the available source entity types."
                            />
                          </Box>
                          <Box>
                            <FormControl mb="4">
                              <FormLabel fontSize="sm">
                                Select a source (optional)
                              </FormLabel>
                              <Select
                                size="sm"
                                useBasicStyles
                                chakraStyles={reactSelectStyles}
                                colorScheme="brand"
                                isClearable
                                value={
                                  dataSourceGroups && selectedSource
                                    ? getSourceOption(
                                        dataSourceGroups,
                                        selectedSource
                                      )
                                    : undefined
                                }
                                onChange={(value) => {
                                  setSelectedSource(
                                    (value as ISelectOption)?.value as string
                                  );
                                }}
                                options={getSourceOptions(dataSourceGroups)}
                                placeholder="Select source"
                              />
                            </FormControl>
                            {selectedSource && (
                              <FormControl>
                                <FormLabel fontSize="sm">
                                  Select one ore more types (optional)
                                </FormLabel>
                                <MultiDropdown
                                  options={sourceEntityTypeOptions()}
                                  selectedOptions={selectedSourceEntityTypes}
                                  onChange={(value) => {
                                    setSelectedSourceEntityTypes(value);
                                  }}
                                  placeholder="Select one or more types"
                                />
                              </FormControl>
                            )}
                          </Box>
                        </Box>
                      )}
                      <Flex pt={8} justifyContent="flex-end">
                        <Button
                          colorScheme="gray"
                          variant="ghost"
                          mr={3}
                          onClick={onClose}
                          isDisabled={isLoading}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          isDisabled={isLoading}
                          isLoading={isLoading}
                          type="submit"
                        >
                          Create
                        </Button>
                      </Flex>
                    </Form>
                  );
                }}
              </Formik>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default CreateSchemaModal;
