import {
  Button,
  ButtonGroup,
  Flex,
  FocusLock,
  FormControl,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import formatDateTimeForHtmlInput from "../../../helpers/formatDateTimeForHtmlInput";
import { useLogsState } from "../contexts/logs";
import {
  getPeriodTitle,
  getRelativePeriod,
  isToNow
} from "../helpers/filterPeriod";

const LogsDatePeriodFilter = () => {
  const now = new Date();
  const { state, dispatch } = useLogsState();

  const [defaultTabIndex, setDefaultTabIndex] = useState<number>(0);
  const [relativeFrom, setRelativeFrom] = useState<string | undefined>();
  const [[absoluteFrom, absoluteTo], setAbsoluteToFrom] = useState<
    [string, string]
  >([
    new Date(now.getTime() - 15 * 60 * 1000).toISOString(), // last 15-minutes
    now.toISOString()
  ]);

  const periodTitle = getPeriodTitle(state.period);
  const absoluteFromMinValue = new Date(
    now.getTime() - 1000 * 60 * 60 * 24 * 30
  ).toISOString();
  const absoluteMaxValue = new Date().toISOString();
  const absoluteToIsRelativeNow = isToNow(absoluteTo);

  const {
    onOpen: onTimeIntervalOpen,
    onClose: onTimeIntervalClose,
    isOpen: isTimeIntervalOpen
  } = useDisclosure();

  const applyRelativePeriod = () => {
    dispatch({
      type: "set_period",
      value: {
        from: relativeFrom,
        to: undefined
      }
    });
    onTimeIntervalClose();
  };

  const applyAbsolutePeriod = () => {
    dispatch({
      type: "set_period",
      value: {
        from: absoluteFrom,
        to: absoluteTo
      }
    });
    onTimeIntervalClose();
  };

  const onChangeAbsoluteToIsRelativeNow = (checked: boolean) => {
    setAbsoluteToFrom([
      absoluteFrom,
      checked ? "now" : new Date().toISOString()
    ]);
  };

  useEffect(() => {
    const setEditTimeInterval = () => {
      const relativePeriod = getRelativePeriod(state.period);
      if (relativePeriod) {
        setDefaultTabIndex(0);
        setRelativeFrom(relativePeriod.value);
      } else if (state.period.from && state.period.to) {
        setDefaultTabIndex(1);
        setAbsoluteToFrom([state.period.from, state.period.to]);
      }
    };

    if (isTimeIntervalOpen) {
      setEditTimeInterval();
    }
  }, [isTimeIntervalOpen]);

  return (
    <>
      <FormControl size="sm">
        <Popover
          isOpen={isTimeIntervalOpen}
          onOpen={onTimeIntervalOpen}
          onClose={onTimeIntervalClose}
          placement="bottom-end"
          closeOnBlur={false}
        >
          <PopoverTrigger>
            <Input
              size="sm"
              width={"260px"}
              value={periodTitle}
              cursor="pointer"
              readOnly
            />
          </PopoverTrigger>
          <PopoverContent p={4}>
            <FocusLock restoreFocus persistentFocus={false}>
              <PopoverArrow />
              <Tabs
                isFitted
                variant="enclosed"
                index={defaultTabIndex}
                onChange={setDefaultTabIndex}
              >
                <TabList mb="1em">
                  <Tab>Relative</Tab>
                  <Tab>Absolute</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <RadioGroup
                      onChange={setRelativeFrom}
                      value={relativeFrom ?? ""}
                    >
                      <Stack direction="column" spacing={0.5}>
                        <Radio value="15m">Last 15 minutes</Radio>
                        <Radio value="1h">Last hour</Radio>
                        <Radio value="1d">Last day</Radio>
                        <Radio value="1w">Last week</Radio>
                        <Radio value="30d">Last 30 days</Radio>
                      </Stack>
                    </RadioGroup>
                    <ButtonGroup
                      display="flex"
                      justifyContent="flex-end"
                      mt={3}
                    >
                      <Button variant="subtle" onClick={onTimeIntervalClose}>
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        isDisabled={!relativeFrom}
                        onClick={applyRelativePeriod}
                      >
                        Apply
                      </Button>
                    </ButtonGroup>
                  </TabPanel>
                  <TabPanel>
                    <Stack spacing={4}>
                      <FormControl>
                        <FormLabel htmlFor="from">From</FormLabel>
                        <Input
                          id="from"
                          type="datetime-local"
                          value={formatDateTimeForHtmlInput(absoluteFrom)}
                          min={formatDateTimeForHtmlInput(absoluteFromMinValue)}
                          max={formatDateTimeForHtmlInput(absoluteMaxValue)}
                          isInvalid={
                            !absoluteFrom || new Date(absoluteFrom) > new Date()
                          }
                          onChange={(e) =>
                            setAbsoluteToFrom([
                              new Date(e.target.value).toISOString(),
                              absoluteTo
                            ])
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="to">To</FormLabel>
                        {!absoluteToIsRelativeNow && (
                          <Input
                            id="to"
                            type="datetime-local"
                            value={formatDateTimeForHtmlInput(absoluteTo)}
                            max={formatDateTimeForHtmlInput(absoluteMaxValue)}
                            onChange={(e) =>
                              setAbsoluteToFrom([
                                absoluteFrom,
                                new Date(e.target.value).toISOString()
                              ])
                            }
                          />
                        )}
                        <Flex mt={2} alignItems={"center"} gap={1}>
                          <Switch
                            id="absolute-to-relative-now"
                            isChecked={absoluteToIsRelativeNow}
                            size="md"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              onChangeAbsoluteToIsRelativeNow(e.target.checked)
                            }
                          />
                          <FormLabel
                            htmlFor="absolute-to-relative-now"
                            fontWeight={"400"}
                            cursor={"pointer"}
                            margin={"unset"}
                          >
                            Now
                          </FormLabel>
                        </Flex>
                      </FormControl>

                      <ButtonGroup
                        display="flex"
                        justifyContent="flex-end"
                        mt={3}
                      >
                        <Button variant="subtle" onClick={onTimeIntervalClose}>
                          Cancel
                        </Button>
                        <Button
                          isDisabled={!absoluteFrom || !absoluteTo}
                          variant="primary"
                          type="submit"
                          onClick={applyAbsolutePeriod}
                        >
                          Apply
                        </Button>
                      </ButtonGroup>
                    </Stack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </FocusLock>
          </PopoverContent>
        </Popover>
      </FormControl>
    </>
  );
};

export default LogsDatePeriodFilter;
