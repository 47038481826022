import { Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import DevEnvBadge from "./components/DevEnvBadge";
import Footer from "./components/Footer";
import FullScreen from "./components/FullScreen/FullScreen";
import Header from "./components/header/Header";
import MetaTitle from "./components/MetaTitle";
import SideMenu from "./components/SideMenu";
import { SidePanelProvider } from "./context/SidePanelContext";
import useLatestAppToast from "./features/appVersion/useLatestAppToast";
import DeprecatedTenantBlocker from "./features/tenants/components/DeprecatedTenantBlocker";
import { useGeneralStore } from "./generalStore";
import MixpanelProvider from "./mixpanel/MixPanelProvider";

const Layout = ({
  enableNavigation = true,
  enableCommandbar = true,
  enableDeploy = true
}: {
  enableNavigation?: boolean;
  enableCommandbar?: boolean;
  enableDeploy?: boolean;
}) => {
  const { isFullscreen } = useGeneralStore(
    useShallow(({ fullscreen }) => ({
      isFullscreen: fullscreen
    }))
  );

  useLatestAppToast();

  return (
    <>
      <MetaTitle></MetaTitle>
      <MixpanelProvider>
        <SidePanelProvider>
          {!isFullscreen && (
            <Header
              enableNavigation={enableNavigation}
              enableCommandbar={enableCommandbar}
              enableDeploy={enableDeploy}
            ></Header>
          )}

          <Flex
            flexDirection={{
              base: "column",
              md: "row"
            }}
          >
            <DeprecatedTenantBlocker>
              {!isFullscreen && <SideMenu></SideMenu>}
              <Flex direction={"column"} grow={1}>
                <Flex minH={"2em"} justify={"flex-end"}>
                  <FullScreen></FullScreen>
                </Flex>
                <Flex mx={"2em"} grow={1}>
                  <Outlet />
                </Flex>
              </Flex>
            </DeprecatedTenantBlocker>
          </Flex>
          {!isFullscreen && <Footer></Footer>}
          <DevEnvBadge />
        </SidePanelProvider>
      </MixpanelProvider>
    </>
  );
};

export default Layout;
