import { registry } from "tsyringe";
import {
  JavascriptCollectionSchemaVisitor,
  JavascriptNormalSchemaVisitor,
  JavascriptPartialSchemaVisitor
} from "./javascript";
import {
  JsonSourceEntityTypesSchemaVisitor,
  JsonSourceGroupTriggerSchemaVisitor
} from "./json";

export const INJECTION_TOKEN_VALIDATION_VISITORS = "VALIDATION_VISITOR";

@registry([
  {
    token: INJECTION_TOKEN_VALIDATION_VISITORS,
    useToken: JavascriptNormalSchemaVisitor
  },
  {
    token: INJECTION_TOKEN_VALIDATION_VISITORS,
    useToken: JavascriptPartialSchemaVisitor
  },
  {
    token: INJECTION_TOKEN_VALIDATION_VISITORS,
    useToken: JavascriptCollectionSchemaVisitor
  },
  {
    token: INJECTION_TOKEN_VALIDATION_VISITORS,
    useToken: JsonSourceEntityTypesSchemaVisitor
  },
  {
    token: INJECTION_TOKEN_VALIDATION_VISITORS,
    useToken: JsonSourceGroupTriggerSchemaVisitor
  }
])
export class ValidationModule {}
