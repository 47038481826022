import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";
import ReloadButton from "../../components/ReloadButton";
import { useInvites } from "../../features/account/api/getInvites";
import ReceivedInvitationsTable from "../../features/account/components/ReceivedInvitationsTable";

const InvitesPage = () => {
  const title = "Received invitations";

  const { data, isLoading, refetch, isRefetching } = useInvites();
  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox
        title={title}
        help="Invitations you have received to join other tenants."
      >
        <ReloadButton loading={isRefetching} onClick={refetch} />

        <ReceivedInvitationsTable data={data} isLoading={isLoading} />
      </ContentBox>
    </>
  );
};

export default InvitesPage;
