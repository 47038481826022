import qs from "qs";
import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { IPaginated } from "../../types/api";
import { ITenant } from "../tenants/types";
import { ITenantSearchRequest } from "./types";

@singleton()
export class AdminService {
  constructor(private readonly managementClient: ManagementClient) {}
  async getTenantsPage(params: ITenantSearchRequest, signal?: AbortSignal) {
    const { data } = await this.managementClient.get<IPaginated<ITenant[]>>(
      "/api/v1/admin/tenants",
      {
        params: {
          term: params.term,
          first: params.first,
          after: params.after
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
        signal
      }
    );
    return data;
  }
}
