import { Box, Flex } from "@chakra-ui/react";
import { Buffer } from "buffer";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ContentBox from "../../components/ContentBox";
import MetaTitle from "../../components/MetaTitle";
import Pagination from "../../components/Pagination";
import ReloadButton from "../../components/ReloadButton";
import {
  LogPageOptions,
  useEnhancedLogs
} from "../../features/logs/api/getLogs";
import LogsFilteringBar from "../../features/logs/components/LogsFilteringBar";
import LogsTable from "../../features/logs/components/LogsTable";
import SelectedColumns from "../../features/logs/components/SelectedColumns";
import ToggleDisplayModeButton from "../../features/logs/components/ToggleDisplayModeButton";
import ToggleInternalLogsVisiblityButton from "../../features/logs/components/ToggleInternalLogsVisiblityButton";
import { useLogsState } from "../../features/logs/contexts/logs";
import { isAbsolutePeriod } from "../../features/logs/helpers/filterPeriod";
import { searchParamsToFilterCondition } from "../../features/logs/helpers/initQueryConditionFromSearchParams";
import { DefaultState } from "../../features/logs/states/logs";
import { ILogsPeriodFilter } from "../../features/logs/types";
import { DisplayType } from "../../features/logs/types/interactivePlaceholders";
import { SortingDirection } from "../../types/dataTable";

const arrayEquals = (a: unknown[], b: unknown[]): boolean => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

const LogsPage = (options?: LogPageOptions) => {
  const title = "Logs";
  const [searchParams, setSearchParams] = useSearchParams();
  const { state, dispatch } = useLogsState();

  const parseQueryStringPeriod = function (
    params: URLSearchParams
  ): ILogsPeriodFilter | null {
    const from = params.get("from") ?? undefined;
    const to = params.get("to") ?? undefined;
    if (!from && !to) {
      return null;
    }

    return {
      from: from,
      to: to
    };
  };

  useEffect(() => {
    const action = searchParamsToFilterCondition(searchParams);
    if (action) {
      dispatch(action);
    }

    // parse from to
    const periodFilter = parseQueryStringPeriod(searchParams);
    if (periodFilter) {
      dispatch({ type: "set_period", value: periodFilter });
    }

    // sorting direction
    const sortingDirection = searchParams.get("sorting_direction") ?? undefined;
    if (sortingDirection) {
      dispatch({
        type: "set_sorting_direction",
        value: sortingDirection as SortingDirection
      });
    }

    // columns
    const selectedColumnsQueryParamAsString =
      searchParams.get("columns") ?? undefined;
    const selectedColumns = selectedColumnsQueryParamAsString?.split(";") ?? [];
    if (
      selectedColumnsQueryParamAsString &&
      !arrayEquals(selectedColumns, DefaultState.selectedColumns)
    ) {
      dispatch({
        type: "set_columns",
        value: selectedColumns
      });
    }

    // display type
    const displayTypeAsString = searchParams.get("display") ?? undefined;
    if (
      displayTypeAsString &&
      displayTypeAsString !== DefaultState.displayType
    ) {
      dispatch({
        type: "set_display_type",
        value: displayTypeAsString as DisplayType
      });
    }
  }, []);

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
    refetch
  } = useEnhancedLogs(state, undefined, {
    tenantId: options?.tenantId
  });

  // Fetch logs data and initialize context
  useEffect(() => {
    if (!isRefetching && data) {
      dispatch({
        type: "initialize",
        items: data.pages.flatMap((page) => page.logLines)
      });
    }
  }, [data, isRefetching]);

  // Update search params when state.condition changes
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(searchParams);
    const sidePanel = searchParams.get("sidePanel");
    for (const key of searchParams.keys()) {
      urlSearchParams.delete(key);
    }

    if (state.condition) {
      const andConditionItems = state.condition.items;
      if (andConditionItems && andConditionItems.length > 0) {
        urlSearchParams.set(
          "condition",
          Buffer.from(JSON.stringify(andConditionItems), "utf8").toString(
            "base64"
          )
        );
      }
    }

    if (state.period && state.period.from !== DefaultState.period.from) {
      state.period.from
        ? urlSearchParams.set("from", state.period.from ?? "")
        : void 0;

      state.period.to
        ? urlSearchParams.set("to", state.period.to ?? "")
        : void 0;
    }

    state.sortingDirection
      ? urlSearchParams.set("sorting_direction", state.sortingDirection ?? "")
      : void 0;
    !arrayEquals(state.selectedColumns, DefaultState.selectedColumns)
      ? urlSearchParams.set("columns", state.selectedColumns.join(";"))
      : void 0;

    sidePanel ? urlSearchParams.set("sidePanel", sidePanel) : void 0;
    state.displayType !== DefaultState.displayType
      ? urlSearchParams.set("display", state.displayType)
      : void 0;

    setSearchParams(urlSearchParams, { replace: true });
  }, [
    state.condition,
    state.period,
    state.sortingDirection,
    state.selectedColumns,
    state.displayType
  ]);
  const entries = data?.pages?.flatMap((page) => page.logLines) ?? [];
  const toNow = !isAbsolutePeriod(state.period);

  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox
        title={title}
        help="Logs lets you view log details from our services (Delivery API, Import Worker, Ingest API, Management API, and Processing Worker)."
      >
        <LogsFilteringBar
          rightStart={<></>}
          rightEnd={
            <Flex direction={"row"} gap={2}>
              <ToggleInternalLogsVisiblityButton></ToggleInternalLogsVisiblityButton>
              <SelectedColumns></SelectedColumns>
              <ToggleDisplayModeButton></ToggleDisplayModeButton>
              <ReloadButton
                loading={isRefetching}
                disabled={!toNow}
                disabledTooltip="Reload disabled for fixed time interval"
                onClick={refetch}
              />
            </Flex>
          }
        ></LogsFilteringBar>
        <Box minHeight={"450px"}>
          <LogsTable loading={isLoading || isRefetching} />
          {data && data?.pages[0]?.totalHits > 0 && (
            <Pagination
              disabled={!hasNextPage}
              current={entries.length}
              total={data?.pages[0]?.totalHits ?? 0}
              loadMore={fetchNextPage}
              loading={isFetchingNextPage}
            ></Pagination>
          )}
        </Box>
      </ContentBox>
    </>
  );
};

export default LogsPage;
