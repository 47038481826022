import { create } from "zustand";
import { ITenant } from "../../tenants/types";
import { IAuthState } from "../types";

export const useAuthStore = create<IAuthState>((set, get) => ({
  adminFeaturesEnabled:
    localStorage.getItem("adminFeaturesEnabled") === "true" &&
    (get()?.isAdmin ?? false),
  user: undefined,
  links: undefined,
  tokens: {},
  isValid: false,
  isAdmin: false,
  addTenant: (tenant: ITenant) =>
    set((state) => ({
      user: {
        ...state.user,
        tenantIds: [...(state.user?.tenantIds ?? []), tenant.id.idValue]
      }
    })),
  register: (authState: Partial<IAuthState>) =>
    set((state) => ({
      ...state,
      ...authState
    })),
  toggleAdminFeatures: () =>
    set((state) => {
      if (!state.isAdmin) {
        return state;
      }
      localStorage.setItem(
        "adminFeaturesEnabled",
        String(!state.adminFeaturesEnabled)
      );
      return {
        ...state,
        adminFeaturesEnabled: !state.adminFeaturesEnabled
      };
    })
}));
