import { Avatar, Text, useDisclosure } from "@chakra-ui/react";
import { faUserSlash } from "@fortawesome/pro-light-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useShallow } from "zustand/react/shallow";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import Pagination from "../../../components/Pagination";
import { DataTable } from "../../../components/table/DataTable";
import capitalizeFirst from "../../../helpers/capitalizeFirst";
import { IPagination } from "../../../types/api";
import { DataTableAction } from "../../../types/dataTable";
import { useTenantUsers } from "../api/getTenantUsers";
import { useRemoveTenantUser } from "../api/removeTenantUser";
import { useTenantStore } from "../store";
import { ITenantUserTable, TenantUsersTableModalType } from "../types";

const TenantUsersTable = () => {
  const { activeTenant } = useTenantStore(
    useShallow(({ activeTenant }) => ({ activeTenant }))
  );
  const pagination: IPagination = {
    first: 5
  };

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useTenantUsers(pagination);

  const pageData = data?.pages.map((page) => page.results).flat() || [];

  const total = data?.pages[0]?.total;

  const tableData = pageData
    ? pageData?.map(
        (item) =>
          ({
            userName: `${item.firstName} ${item.lastName}`,
            userRole: item.tenants[activeTenant.id]
              ? item.tenants[activeTenant.id].join(",")
              : "",
            userGuid: item.id.userGuid,
            id: item.id.idValue,
            email: item.emails.join(", ")
          } as ITenantUserTable)
      )
    : [];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState<TenantUsersTableModalType>();
  const [rowData, setRowData] = useState<ITenantUserTable | null>(null);

  const columnHelper = createColumnHelper<ITenantUserTable>();

  const columns = [
    columnHelper.accessor("userName", {
      cell: (info) => (
        <>
          <Avatar size="xs" mr="2" name={info.getValue()} /> {info.getValue()}
        </>
      ),
      header: "Name"
    }),
    columnHelper.accessor("email", {
      cell: (info) => info.getValue(),
      header: "Email"
    }),
    columnHelper.accessor("userRole", {
      cell: (info) => capitalizeFirst(info.getValue()),
      header: "Role"
    })
  ];

  const handleActionClick = (
    row: ITenantUserTable,
    type: TenantUsersTableModalType
  ) => {
    setRowData(row);
    setModalType(type);
    onOpen();
  };

  const actions: DataTableAction<
    TenantUsersTableModalType,
    ITenantUserTable
  >[] = [
    {
      type: TenantUsersTableModalType.REMOVE_FROM_TENANT,
      icon: faUserSlash,
      onClick: (row: ITenantUserTable) => {
        handleActionClick(row, TenantUsersTableModalType.REMOVE_FROM_TENANT);
      }
    }
  ];

  const ActionModal = ({
    type,
    rowData,
    isOpen,
    onClose
  }: {
    type: TenantUsersTableModalType;
    rowData: ITenantUserTable;
    isOpen: boolean;
    onClose: () => void;
  }) => {
    const removeTenantUser = useRemoveTenantUser();

    if (type === TenantUsersTableModalType.REMOVE_FROM_TENANT) {
      return (
        <ConfirmModal
          title="Remove from tenant"
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={() => {
            removeTenantUser.mutate({ id: rowData.userGuid });
            onClose();
          }}
        >
          <Text>
            Are you sure you want to remove{" "}
            <Text as="b">{rowData.userName}</Text> from{" "}
            <Text as="b">{activeTenant.name}</Text>? The user will no longer be
            able to login to this tenant.
          </Text>
        </ConfirmModal>
      );
    }
    return <></>;
  };

  return (
    <>
      <DataTable
        loading={isLoading}
        columns={columns}
        data={tableData}
        actions={actions}
      />
      {tableData.length > 0 && (
        <Pagination
          disabled={!hasNextPage || isLoading || isFetchingNextPage}
          current={pageData.length}
          total={total as number}
          loadMore={() => fetchNextPage()}
          loading={isFetchingNextPage}
        />
      )}
      {rowData && modalType && (
        <ActionModal
          type={modalType}
          rowData={rowData}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default TenantUsersTable;
